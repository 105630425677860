import React from 'react';
import banner2 from '../../../assets/2.jpg';
import banner2Mobile from '../../../assets/2 B.jpg'; 
import './Section4.css';

const Section4 = () => {
  const isMobile = window.innerWidth < 768;

  return (
    <div className='banner-container'>
      <img 
        className='banner' 
        data-aos="fade-down" 
         data-aos-once="true"
        src={isMobile ? banner2Mobile : banner2} 
        alt='banner' 
      />
    </div>
  );
}

export default Section4;
