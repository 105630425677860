import React from 'react'
import notfound from "../../assets/404.jpg"

const NotFound = () => {
  return (
    <div>
      <img src={notfound} alt='NotFound'/>
    </div>
  )
}

export default NotFound