import React, { useState, useEffect } from "react";
import "./CheckoutForm.css";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import AxiosClient from "utilities/axiosClient";
import { useCart } from "contexts/CartContext";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion, AnimatePresence } from "framer-motion";
import { CheckCircle2 } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faTruck } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
dayjs.extend(utc);
dayjs.extend(timezone);

const CheckoutForm = () => {
  const [selectedMethod, setSelectedMethod] = useState("credit-card");
  const { cart, fetchCart, updateOrCreateCart, currency } = useCart();
  const [couponCode, setCouponCode] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [email, setEmail] = useState("");
  const [stripeErrors, setStripeErrors] = useState({});
  const navigate = useNavigate();
  const [isPakistaniUser, setIsPakistaniUser] = useState(false);
  const [shippingInfo, setShippingInfo] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    emirate: "",
    phone: "",
    country: "UAE",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState({});
  const [stripeCompleteness, setStripeCompleteness] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    amount: "",
    date: "",
    referenceNumber: "",
  });

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  useEffect(() => {
    const userTimezone = dayjs.tz.guess(); // e.g., 'Asia/Karachi'

    // List of Pakistan timezones
    const pakistanTimezones = ["Asia/Karachi"];

    if (pakistanTimezones.includes(userTimezone)) {
      setIsPakistaniUser(true);
    } else {
      setIsPakistaniUser(false);
    }
  }, []);

  useEffect(() => {
    if (!isPakistaniUser && selectedMethod === "COD") {
      setSelectedMethod("credit-card");
    }
  }, [isPakistaniUser, selectedMethod]);

  const totalAmount = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const handleApplyCoupon = async () => {
    if (!couponCode) {
      setCouponError("Please enter a coupon code.");
      return;
    }

    setIsCouponLoading(true);
    setCouponError("");

    try {
      const response = await AxiosClient.get(
        `/coupons/validate-coupon?code=${encodeURIComponent(couponCode)}`
      );

      const { isValid, code, discountPercent } = response.data;

      if (!isValid) {
        setCouponError("Invalid or expired coupon code.");
        setIsCouponLoading(false);
        return;
      }

      // If valid, store it in state
      setAppliedCoupon({
        code,
        discountPercent,
      });

      toast.success(`Coupon applied! You get ${discountPercent}% off.`, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.error("Coupon validation error:", error);
      setCouponError(
        error.response?.data?.error || "Failed to validate coupon."
      );
    } finally {
      setIsCouponLoading(false);
    }
  };

  const calculateTotalAmount = () => {
    let total = cart.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    if (appliedCoupon && appliedCoupon.discountPercent) {
      const discount = (total * appliedCoupon.discountPercent) / 100;
      total -= discount;
    }
    return total.toFixed(2);
  };

  const customStyle = {
    base: {
      fontSize: "15px",
      color: "#333",
      fontFamily: "Arial, sans-serif",
      "::placeholder": { color: "#999" },
      padding: "10px 12px",
    },
    invalid: { color: "#fa755a" },
  };

  const cityOptions =
    shippingInfo.country === "AE"
      ? ["Dubai", "Abu Dhabi", "Sharjah"]
      : ["Karachi", "Lahore", "Islamabad"];

  const handleValidation = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Enter an email";
    if (!shippingInfo.firstName) newErrors.firstName = "Enter a first name";
    if (!shippingInfo.lastName) newErrors.lastName = "Enter a last name";
    if (!shippingInfo.address) newErrors.address = "Enter an address";
    if (!shippingInfo.city) newErrors.city = "Enter a city";
    if (!shippingInfo.phone) newErrors.phone = "Enter a phone number";

    setErrors(newErrors);

    const hasFormErrors = Object.keys(newErrors).length > 0;

    if (selectedMethod === "credit-card") {
      const stripeElementErrors = {};

      if (!stripeCompleteness.cardNumber) {
        stripeElementErrors.cardNumber = "Enter a valid card number";
      }
      if (!stripeCompleteness.cardExpiry) {
        stripeElementErrors.cardExpiry = "Enter a valid expiry date";
      }
      if (!stripeCompleteness.cardCvc) {
        stripeElementErrors.cardCvc = "Enter a valid CVC";
      }

      setStripeErrors((prevErrors) => ({
        ...prevErrors,
        ...stripeElementErrors,
      }));

      const hasStripeErrors = Object.keys(stripeElementErrors).length > 0;

      return !hasFormErrors && !hasStripeErrors;
    } else {
      // For COD, ignore Stripe errors
      setStripeErrors({});
      return !hasFormErrors;
    }
  };

  const handleStripeElementChange = (event) => {
    const { elementType, error, complete } = event;

    setStripeErrors((prevErrors) => ({
      ...prevErrors,
      [elementType]: error ? error.message : "",
    }));

    setStripeCompleteness((prevCompleteness) => ({
      ...prevCompleteness,
      [elementType]: complete,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!handleValidation()) return;

      setIsProcessing(true);

      if (cart.length === 0) {
        toast.error("Cart cannot be empty", {
          position: "top-center",
          autoClose: 5000,
        });
        setIsProcessing(false);
        return;
      }

      const outOfStockProducts = cart.filter((item) => item.outOfStock);
      if (outOfStockProducts.length > 0) {
        toast.error("Some products in your cart are out of stock.", {
          position: "top-center",
          autoClose: 5000,
        });
        setIsProcessing(false);
        return;
      }

      let paymentIntentId = null;

      if (selectedMethod === "credit-card") {
        if (!stripe || !elements) {
          toast.error("Stripe is not loaded", {
            position: "top-center",
            autoClose: 5000,
          });
          setIsProcessing(false);
          return;
        }

        // Proceed with Stripe payment processing
        const response = await AxiosClient.post(
          "/payment/create-payment-intent",
          { amount: totalAmount, currency }
        );
        const clientSecret = response.data.clientSecret;

        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: `${shippingInfo.firstName} ${shippingInfo.lastName}`,
              email: email,
              address: {
                line1: shippingInfo.address,
                city: shippingInfo.city,
                country: shippingInfo.country,
              },
            },
          },
        });

        if (result.error) {
          setIsProcessing(false);
          toast.error("Transaction failed. Please try again.", {
            position: "top-center",
            autoClose: 5000,
          });
          return;
        } else if (result.paymentIntent.status === "succeeded") {
          paymentIntentId = result.paymentIntent.id;
        } else {
          setIsProcessing(false);
          toast.error("Payment not successful. Please try again.", {
            position: "top-center",
            autoClose: 5000,
          });
          return;
        }
      }

      // Proceed to process order
      const orderResponse = await AxiosClient.post("/payment/process-order", {
        deliveryInfo: shippingInfo,
        email,
        cart,
        paymentIntentId,
        amount: totalAmount.toFixed(2),
        paymentMethod: selectedMethod === "credit-card" ? "STRIPE" : "COD",
        currency,
        couponCode,
      });

      setPaymentDetails({
        amount: `${currency} ${totalAmount.toFixed(2)}`,
        date: new Date().toLocaleString(),
        referenceNumber: orderResponse.data.orderId,
      });

      setIsProcessing(false);
      setIsModalOpen(true);
    } catch (error) {
      setIsProcessing(false);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const handleInputChange = (field, value) => {
    setShippingInfo({ ...shippingInfo, [field]: value });
    setErrors({ ...errors, [field]: "" });
  };
  return (
    <div className="checkout-container">
      <form className="checkout-form" onSubmit={handleSubmit}>
        <h2 className="heading-CheckoutForm">
          <FontAwesomeIcon icon={faCircleCheck} /> Contact Information
        </h2>

        <div className="two-input">
          <div className="field-checkout">
            <label>First Name</label>
            <input
              type="text"
              className={`checkout-input ${
                errors.firstName ? "input-error" : ""
              }`}
              placeholder="First name"
              onChange={(e) => handleInputChange("firstName", e.target.value)}
            />
            {errors.firstName && (
              <p className="error-text">{errors.firstName}</p>
            )}
          </div>
          <div className="field-checkout">
            <label>Last Name</label>
            <input
              type="text"
              className={`checkout-input ${
                errors.lastName ? "input-error" : ""
              }`}
              placeholder="Last name"
              onChange={(e) => handleInputChange("lastName", e.target.value)}
            />
            {errors.lastName && <p className="error-text">{errors.lastName}</p>}
          </div>
        </div>
        <div className="field-checkout">
          <label>Email</label>
          <input
            type="email"
            className={`checkout-input ${errors.email ? "input-error" : ""}`}
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrors({ ...errors, email: "" });
            }}
          />
          {errors.email && <p className="error-text">{errors.email}</p>}
        </div>
        <div className="field-checkout">
          <label>Phone</label>
          <input
            type="tel"
            className={`checkout-input ${errors.phone ? "input-error" : ""}`}
            placeholder="Phone number"
            onChange={(e) => handleInputChange("phone", e.target.value)}
          />
          {errors.phone && <p className="error-text">{errors.phone}</p>}
        </div>

        <div className="under-line"></div>
        {/* <div className="checkbox">
          <input className="checkbox-tick" type="checkbox" id="promotions" />
          <label htmlFor="promotions">
            Send me order information, promotions, and deals on Email and
            WhatsApp
          </label>
        </div> */}

        <h2 className="heading-CheckoutForm">
          <FontAwesomeIcon icon={faCircleCheck} /> Shipping Address
        </h2>
        <div className="delivery-section">
          <div className="field-checkout">
            <label>Address</label>
            <input
              type="text"
              className={`checkout-input ${
                errors.address ? "input-error" : ""
              }`}
              placeholder="Address"
              onChange={(e) => handleInputChange("address", e.target.value)}
            />
            {errors.address && <p className="error-text">{errors.address}</p>}
          </div>
          <div className="two-input">
            <div className="field-checkout">
              <label>City</label>
              <input
                type="text"
                className={`checkout-input ${errors.city ? "input-error" : ""}`}
                placeholder="City"
                onChange={(e) => handleInputChange("city", e.target.value)}
                list="city-options"
              />
              {errors.city && <p className="error-text">{errors.city}</p>}

              <datalist id="city-options">
                {cityOptions.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="field-checkout">
              <label>ZIP Code</label>
              <input
                type="tel"
                className={`checkout-input ${errors.zip ? "input-error" : ""}`}
                placeholder="0000"
              />
            </div>
          </div>
          <div className="field-checkout">
            <label>Country</label>
            <select
              name="country"
              value={shippingInfo.country}
              onChange={(e) => handleInputChange("country", e.target.value)}
            >
              <option value="AE">United Arab Emirates</option>
              <option value="PK">Pakistan</option>
            </select>
          </div>
          {/* <div className="checkbox">
            <input className="checkbox-tick" type="checkbox" id="saveInfo" />
            <label htmlFor="saveInfo">
              Save this information for next time
            </label>
          </div> */}
        </div>
        <div className="under-line"></div>
        <h2 className="heading-CheckoutForm">
          <FontAwesomeIcon icon={faCircleCheck} /> Payment Method
        </h2>
        {isPakistaniUser ? (
          <>
            {/* Credit Card Option */}
            <div className="payment-option">
              <label className="option-label">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="credit-card"
                  checked={selectedMethod === "credit-card"}
                  onChange={() => setSelectedMethod("credit-card")}
                />
                <span className="option-icon">
                  <FontAwesomeIcon icon={faCreditCard} />
                </span>
                <span className="option-text">Credit Card</span>
              </label>
            </div>

            {/* COD Option */}
            <div className="payment-option">
              <label className="option-label">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="COD"
                  checked={selectedMethod === "COD"}
                  onChange={() => setSelectedMethod("COD")}
                />
                <span className="option-icon">
                  <FontAwesomeIcon icon={faTruck} />
                </span>
                <span className="option-text">Cash on Delivery</span>
              </label>
            </div>
          </>
        ) : (
          // For users not in Pakistan, only show Credit Card option
          <div className="payment-option">
            <label className="option-label">
              <input
                type="radio"
                name="paymentMethod"
                value="credit-card"
                checked={selectedMethod === "credit-card"}
                onChange={() => setSelectedMethod("credit-card")}
              />
              <span className="option-icon">
                <FontAwesomeIcon icon={faCreditCard} />
              </span>
              <span className="option-text">Credit Card</span>
            </label>
          </div>
        )}
        {/* Conditionally render card details section if Credit Card is selected */}
        {selectedMethod === "credit-card" && (
          <div>
            <div className="checkout-input">
              <label>Card Number</label>
              <CardNumberElement
                className="stripe-element"
                options={{ style: customStyle }}
                onChange={handleStripeElementChange}
              />
              {stripeErrors.cardNumber && (
                <p className="error-text">{stripeErrors.cardNumber}</p>
              )}
            </div>

            <div className="two-input">
              <div className="checkout-input">
                <label>Expiry Date</label>
                <CardExpiryElement
                  className="stripe-element"
                  options={{ style: customStyle }}
                  onChange={handleStripeElementChange}
                />
                {stripeErrors.cardExpiry && (
                  <p className="error-text">{stripeErrors.cardExpiry}</p>
                )}
              </div>

              <div className="checkout-input">
                <label>CVV</label>
                <CardCvcElement
                  className="stripe-element"
                  options={{ style: customStyle }}
                  onChange={handleStripeElementChange}
                />
                {stripeErrors.cardCvc && (
                  <p className="error-text">{stripeErrors.cardCvc}</p>
                )}
              </div>
            </div>
          </div>
        )}

        <button
          type="submit"
          disabled={isProcessing || !stripe}
          className="pay-now"
        >
          {isProcessing ? "Processing..." : "Place Order "}
          {!isProcessing && (
            <FontAwesomeIcon
              icon={faArrowRightLong}
              className="placeorder-icon"
            />
          )}
        </button>

        {/* <button
          className="pay-now"
          onClick={() => navigate("/shop")}
        >
          Continue Shopping
        </button> */}
      </form>

      <div className="product-details">
        <h2 className="heading-CheckoutForm">Order Summary</h2>
        {cart.map((item) => (
          <div key={item.id} className="product-info-CF">
            <img
              src={item.imageUrl}
              alt={item.name}
              className="product-image-CS"
            />
            <div className="product-detail-CS">
              <div className="name-price">
                <p className="product-name">{item.name}</p>
                <p className="product-quantity">Quantity: {item.quantity}</p>
                {/* <p className="product-name">{item.subscription} </p> */}
                <div className="item-price">
                  {item.currency} {(item.price * item.quantity).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="under-line"></div>
        <div className="price-summary">
          <div className="subtotal">
            <span>Subtotal</span>
            <span>{/* {currency} {subtotal.toFixed(2)} */}</span>
          </div>
          <div className="shipping">
            <span>Shipping</span>
            <span>{/* {currency} {shippingCost.toFixed(2)} */}Free</span>
          </div>

          <div className="coupon">
            <label htmlFor="coupon">Apply Coupon</label>
            {couponError && <p className="error-text">{couponError}</p>}
            <div className="coupon-input">
              <input
                type="text"
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={(e) => {
                  setCouponCode(e.target.value);
                  setCouponError("");
                }}
              />
              <button onClick={handleApplyCoupon} disabled={isCouponLoading}>
                {isCouponLoading ? "Checking..." : "Apply Coupon"}
              </button>
              {appliedCoupon && (
                <p className="success-text">
                  Coupon "{appliedCoupon.code}" applied!{" "}
                  {appliedCoupon.discountPercent}% off.
                </p>
              )}
            </div>
          </div>

          <div className="total">
            <span>Total</span>
            <span>
              {currency} {calculateTotalAmount()}
            </span>
          </div>
        </div>
        {/* <button className="place-order-btn">Place Order</button> */}
        <div className="footer-notes">
          <span>Secure Payment</span>
          <span>Free Shipping</span>
        </div>

        <div className="terms-links">
          <a href="/privacy-policy">Privacy policy</a>
          <a href="/terms-conditions">Terms & Conditions</a>
          <a href="/ContactUs">Contact Us</a>
        </div>
      </div>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="modal-overlay"
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              className="modal-content"
            >
              <div className="flex justify-center items-center h-full">
                <div className="success-icon">
                  <CheckCircle2 className="w-16 h-16 text-green-500" />
                </div>
              </div>

              <h2 className="heading-CheckoutForm">Payment Successful</h2>
              <p className="thank-you-text">Thank you for your purchase!</p>

              <div className="transaction-details">
                {selectedMethod === "credit-card" && (
                  <div className="detail-row">
                    <span className="detail-label">Amount Paid:</span>
                    <span className="detail-value">
                      {paymentDetails.amount}
                    </span>
                  </div>
                )}
                <div className="detail-row">
                  <span className="detail-label">Date & Time:</span>
                  <span className="detail-value">{paymentDetails.date}</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">Order Id:</span>
                  <span className="detail-value">
                    {paymentDetails.referenceNumber}
                  </span>
                </div>
              </div>

              <button onClick={() => navigate("/")} className="return-button">
                Return to Homepage
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <ToastContainer />
    </div>
  );
};

const CheckoutWrapper = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default CheckoutWrapper;
