import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
} from 'react';
import AxiosClient from 'utilities/axiosClient';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  // States and refs
  const [cart, setCart] = useState([]);
  const [currency, setCurrency] = useState('');
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [loadingItems, setLoadingItems] = useState([]); // For tracking loading products

  const userTimezone = dayjs.tz.guess();

  const pendingUpdatesRef = useRef({}); // For pending updates

  // Fetch Cart from Backend
  const fetchCart = useCallback(async () => {
    try {
      const response = await AxiosClient.get('/cart', {
        params: {
          timezone: userTimezone,
        },
      });
      setCart(response.data.result.cartItems);
      setCurrency(response.data.currency);
    } catch (error) {
      console.error('Error fetching cart:', error);
    }
  }, [userTimezone]);

  // Toggle cart visibility and fetch cart items on open
  const toggleCart = () => {
    setIsCartOpen((prevIsCartOpen) => {
      if (!prevIsCartOpen) {
        fetchCart();
      }
      return !prevIsCartOpen;
    });
  };

  // Debounced function to send batch updates
  const debouncedUpdateCart = useCallback(
    debounce(async () => {
      const updates = Object.values(pendingUpdatesRef.current);

      if (updates.length === 0) return;

      // Set loading state for the products being updated
      setLoadingItems((prevLoadingItems) => [
        ...prevLoadingItems,
        ...updates.map((update) => update.productId),
      ]);

      try {
        // Send batch updates to the backend
        await AxiosClient.post(
          '/cart/update-or-create',
          { updates },
          { withCredentials: true },
        );

        // Clear pending updates
        pendingUpdatesRef.current = {};

        // Re-fetch cart to ensure consistency
        await fetchCart();
      } catch (error) {
        console.error('Error updating cart:', error);
        // Re-fetch cart to revert optimistic UI in case of error
        await fetchCart();
      } finally {
        // Remove products from loading state
        setLoadingItems((prevLoadingItems) =>
          prevLoadingItems.filter(
            (productId) =>
              !updates.some((update) => update.productId === productId),
          ),
        );
      }
    }, 500),
    [fetchCart],
  );

  const updateOrCreateCart = (productId, quantity, productDetails = null) => {
    // Optimistically update cart state
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (item) => item.productId === productId,
      );

      if (existingItemIndex !== -1) {
        if (quantity > 0) {
          const updatedCart = [...prevCart];
          updatedCart[existingItemIndex] = {
            ...updatedCart[existingItemIndex],
            quantity,
          };
          return updatedCart;
        } else {
          return prevCart.filter((item) => item.productId !== productId);
        }
      } else if (quantity > 0 && productDetails) {
        return [
          ...prevCart,
          {
            productId,
            quantity,
            name: productDetails.name,
            price: productDetails.price,
            currency: productDetails.currency,
            imageUrl: productDetails.imageUrl,
          },
        ];
      } else {
        return prevCart;
      }
    });

    // Update pending updates
    pendingUpdatesRef.current = {
      ...pendingUpdatesRef.current,
      [productId]: { productId, quantity },
    };

    // Call debounced function
    debouncedUpdateCart();
  };

  // Remove item from cart
  const removeItem = (productId) => {
    updateOrCreateCart(productId, 0);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        fetchCart,
        isCartOpen,
        toggleCart,
        updateOrCreateCart,
        removeItem,
        currency,
        loadingItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
