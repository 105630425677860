import React from "react";
import "./Loader.css"; 

import loader from '../../assets/loader.svg'

const Loader = () => (
  <div className="loader-overlay">
        <img src={loader} alt="loader" />
    
  </div>
);

export default Loader;