import React, { useState } from 'react';
import AxiosClient from 'utilities/axiosClient';
import { useAdminAuth } from 'contexts/AdminAuthContext';

const AttachTrackingIdForm = ({ orderId, currentTrackingId }) => {
  const [trackingId, setTrackingId] = useState(currentTrackingId || '');
  const [loading, setLoading] = useState(false);
  const { admin } = useAdminAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await AxiosClient.put(
        `/orders/${orderId}`,
        { trackingId },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        },
      );
      alert('Tracking ID updated successfully');
      window.location.reload();
    } catch (error) {
      console.error('Error updating tracking ID:', error);
      alert('Failed to update tracking ID');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='tracking-id-form'>
      <label>
        Tracking ID:
        <input
          type='text'
          value={trackingId}
          onChange={(e) => setTrackingId(e.target.value)}
          required
        />
      </label>
      <button type='submit' disabled={loading}>
        {loading ? 'Updating...' : 'Update Tracking ID'}
      </button>
    </form>
  );
};

export default AttachTrackingIdForm;
