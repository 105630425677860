import React, { useState, useEffect } from 'react';
import AxiosClient from 'utilities/axiosClient';
import { MobileStepper, Button, useMediaQuery } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import './Section15.css';

const Section15 = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:1024px)');

  const itemsPerSlide = isDesktop ? 3 : isTablet ? 2 : 1;

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await AxiosClient.get('/reviews/get-all-reviews');
        setTestimonials(response.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, []);

  const maxSteps = Math.ceil(testimonials.length / itemsPerSlide);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep < maxSteps - 1) {
        return prevActiveStep + 1;
      }
      return prevActiveStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep > 0) {
        return prevActiveStep - 1;
      }
      return prevActiveStep;
    });
  };

  const currentItems = testimonials.slice(
    activeStep * itemsPerSlide,
    (activeStep + 1) * itemsPerSlide,
  );

  return (
    <div className='testimonial-section'>
      <div
        className='testimonial-container'
        style={{ display: 'flex', gap: '20px', overflow: 'hidden' }}
      >
        {currentItems.map((testimonial, index) => (
          <div
            className='testimonial-card'
            key={index}
            style={{ flex: '1 1 auto' }}
          >
            {/* Display product image */}
            <img
              src={testimonial.product.productImages[0]?.file.url}
              alt={testimonial.product.name}
              className='section15-product-image'
            />
            {/* Display rating */}
            <div className='rating'>
              {Array.from({ length: 5 }).map((_, i) => (
                <span key={i}>{i < testimonial.rating ? '★' : '☆'}</span>
              ))}
            </div>
            {/* Display comment */}
            <p className='quote'>“{testimonial.comment}”</p>
            {/* Display user name and product name */}
            <div className='section15-profile'>
              <div className='profile-info'>
                <p className='name'>
                  @{testimonial.user.firstName} {testimonial.user.lastName}
                </p>
                <p className='product'>Product: {testimonial.product.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <MobileStepper
        variant='dots'
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        nextButton={
          <Button
            size='small'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight sx={{ color: 'white' }} />
          </Button>
        }
        backButton={
          <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft sx={{ color: 'white' }} />
          </Button>
        }
        sx={{
          background: '#0B1B32',
          color: 'white',
          marginTop: '20px',
          borderRadius: '99px',
          '.MuiMobileStepper-dotActive': {
            backgroundColor: 'white',
          },
        }}
      />
    </div>
  );
};

export default Section15;
