import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Section3.css";
import CartSlider from "../../../components/CartSlider";
import { useCart } from "../../../contexts/CartContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import AxiosClient from "utilities/axiosClient";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

dayjs.extend(utc);
dayjs.extend(timezone);

const Section3 = () => {
  const [products, setProducts] = useState([]);
  const { cart, isCartOpen, toggleCart, updateOrCreateCart } = useCart();
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Check if the screen size is mobile
    const handleResize = () => setIsMobile(window.innerWidth < 900);
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const userTimezone = dayjs.tz.guess();

        const response = await AxiosClient.get("/products", {
          params: {
            timezone: userTimezone,
          },
        });
        setProducts(response.data);
      } catch (error) {
        setProducts([]);
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const handleAddToCart = (product) => {
    if (product.outOfStock) {
      toast.error("Product is out of stock", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    const existingCartItem = cart.find((item) => item.productId === product.id);
    const newQuantity = existingCartItem ? existingCartItem.quantity + 1 : 1;

    updateOrCreateCart(product.id, newQuantity, {
      name: product.name,
      price: product.price,
      currency: product.currency,
      imageUrl: product.productImages[0]?.file?.url || product.img,
    });

    if (!isCartOpen) toggleCart();
  };

  const handleNavigate = (productId) => {
    navigate(`/collections/products/${productId}`);
  };

  const formatPrice = (price) => {
    const numPrice = parseFloat(price);
    return isNaN(numPrice) ? "0.00" : numPrice.toFixed(2);
  };

  return (
    <div className="top-products3">
      <div className="container3">
        <div className="header-section3">
          <h1 data-aos="fade-down" className="heading3">Our Products</h1>
        </div>

        {loading ? (
          <div className="products-grid3">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="product-card3 section3-skeleton">
                <div className="image-section3 section3-skeleton-image"></div>
                <div className="details-section3">
                  <div className="section3-skeleton-text title"></div>
                  <div className="section3-skeleton-text price"></div>
                  <div className="section3-skeleton-text rating"></div>
                  <div className="section3-skeleton-button"></div>
                </div>
              </div>
            ))}
          </div>
        ) : isMobile ? (
          <div className="scrollable-products3">
            {products.map((product) => (
              <div
                key={product.id}
                className="product-card3"
                onClick={() => handleNavigate(product.id)} // Add handleNavigate
              >
                <div className="image-section3">
                  <img
                    src={product.img || product.productImages[0]?.file?.url}
                    alt={product.title}
                    className="product-image3"
                  />
                </div>
                <div className="details-section3">
                  <h1 className="product-title3">{product.name}</h1>
                  <p className="product-price3">
                    {product.currency} {formatPrice(product.price)}
                  </p>
                  <div className="star-rating3">
                    {[...Array(4)].map((_, i) => (
                      <FaStar key={i} className="star3" />
                    ))}
                  </div>
                  <button
                    className="order-button3"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering navigation
                      handleAddToCart(product);
                    }}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="products-grid3">
            {products.map((product) => (
              <div
                key={product.id}
                className="product-card3"
                onClick={() => handleNavigate(product.id)} // Add handleNavigate
              >
                <div className="image-section3">
                  <img
                    src={product.img || product.productImages[0]?.file?.url}
                    alt={product.name}
                    className="product-image3"
                  />
                </div>
                <div className="details-section3">
                  <h1 className="product-title3">{product.name}</h1>
                  <p className="product-price3">
                    {product.currency} {formatPrice(product.price)}
                  </p>
                  <div className="star-rating3">
                    {[...Array(4)].map((_, i) => (
                      <FaStar key={i} className="star3" />
                    ))}
                  </div>
                  <button
                    className="order-button3"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering navigation
                      handleAddToCart(product);
                    }}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        <ToastContainer />
        <CartSlider />
      </div>
    </div>
  );
};

export default Section3;
