import React, { useEffect, useState } from "react";
import "./VideoOverlay.css"; // Include the CSS for styling

import video from '../../assets/video.mp4';

const VideoOverlay = () => {
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    // Check if the video has already been played
    const hasPlayed = localStorage.getItem("videoPlayed");
    if (!hasPlayed) {
      setShowVideo(true);

      // Set a timer to hide the video after 10 seconds
      const timer = setTimeout(() => {
        setShowVideo(false);
        localStorage.setItem("videoPlayed", "true"); // Mark the video as played
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, []);

  if (!showVideo) return null; 
  return (
    <div className="video-overlay">
      <video
        autoPlay
        muted
        playsInline
        onEnded={() => {
          setShowVideo(false);
          localStorage.setItem("videoPlayed", "true"); // Mark the video as played
        }}
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoOverlay;



// useEffect(() => {
//     const hasPlayed = localStorage.getItem('videoPlayed');
//     if (hasPlayed) {
//       setShowVideo(false);
//     } else {
//       localStorage.setItem('videoPlayed', 'true');
//     }

//     const timer = setTimeout(() => {
//       setShowVideo(false);
//     }, 10000);

//     return () => clearTimeout(timer); // Cleanup the timer
//   }, []);
