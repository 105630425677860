import React, { useState, useEffect } from 'react';
import AxiosClient from 'utilities/axiosClient';
import { useAdminAuth } from 'contexts/AdminAuthContext';
import './AdminOrder.css';
import { useNavigate } from 'react-router';

const AdminOrdersPage = () => {
  const { admin } = useAdminAuth();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [stats, setStats] = useState({
    totalOrders: 0,
    revenuePerCurrency: [],
    newCustomers: 0,
    totalSales: 0,
  });

  const [filters, setFilters] = useState({
    orderStatus: '',
    sortBy: 'createdAt',
    sortOrder: 'desc',
    dateFrom: '',
    dateTo: '',
    priceFrom: '',
    priceTo: '',
    searchQuery: '',
    limit: 10,
    page: 1,
  });
  const [totalPages, setTotalPages] = useState(1);

  const orderStatuses = [
    { status: 'started', color: '#ffc107' }, // Yellow
    { status: 'delivering', color: '#17a2b8' }, // Blue
    { status: 'delivered', color: '#28a745' }, // Green
    { status: 'cancelled', color: '#dc3545' }, // Red
    { status: 'complete', color: '#6c757d' }, // Gray
    { status: 'returned', color: '#ff5722' }, // Orange
  ];

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await AxiosClient.get('/orders', {
        params: filters,
        headers: {
          Authorization: `Bearer ${admin.token}`,
        },
      });
      setOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStats = async () => {
    try {
      const response = await AxiosClient.get('/orders/all/stats', {
        headers: {
          Authorization: `Bearer ${admin.token}`,
        },
      });
      setStats(response.data);
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  useEffect(() => {
    fetchStats();
    fetchOrders();
  }, [filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
      page: 1, // Reset to first page on filter change
    }));
  };

  const handlePageChange = (newPage) => {
    setFilters((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchOrders();
  };

  const navigateToOrderDetails = (orderId) => {
    navigate(`/admin/secret-dashboard/orders/${orderId}`);
  };

  const navigateToAttachTracking = (orderId) => {
    navigate(`/admin/secret-dashboard/orders/${orderId}/attach-tracking`);
  };

  return (
    <div className='admin-orders-page'>
      <h1>Order Management Dashboard</h1>

      {/* Dashboard Statistics */}
      <div className='dashboard-stats'>
        <div className='stat-card'>
          <h2>{stats.totalOrders}</h2>
          <p>Total Orders</p>
        </div>
        {stats.revenuePerCurrency.map((rev) => (
          <div key={rev.currency} className='stat-card'>
            <h2>
              {rev.currency} {rev.totalRevenue.toFixed(2)}
            </h2>
            <p>Total Revenue ({rev.currency})</p>
          </div>
        ))}
        <div className='stat-card'>
          <h2>{stats.newCustomers}</h2>
          <p>New Customers</p>
        </div>
        <div className='stat-card'>
          <h2>{stats.totalSales}</h2>
          <p>Total Sales</p>
        </div>
      </div>

      {/* Tabs for Order Status */}
      <div className='tabs'>
        {['All Orders', ...orderStatuses.map((os) => os.status)].map(
          (status, index) => (
            <button
              key={index}
              className={
                filters.orderStatus === status.toLowerCase() ? 'active' : ''
              }
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  orderStatus:
                    status.toLowerCase() === 'all orders'
                      ? ''
                      : status.toLowerCase(),
                }))
              }
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </button>
          ),
        )}
      </div>

      {/* Filters */}
      <div className='filters'>
        <div className='filter-group'>
          <label>Sort By:</label>
          <select
            name='sortBy'
            value={filters.sortBy}
            onChange={handleFilterChange}
          >
            <option value='createdAt'>Created At</option>
            <option value='updatedAt'>Updated At</option>
            <option value='totalAmount'>Total Amount</option>
          </select>
        </div>
        <div className='filter-group'>
          <label>Date Range:</label>
          <input
            type='date'
            name='dateFrom'
            value={filters.dateFrom}
            onChange={handleFilterChange}
          />
          <input
            type='date'
            name='dateTo'
            value={filters.dateTo}
            onChange={handleFilterChange}
          />
        </div>
        <div className='filter-group'>
          <label>Price Range:</label>
          <input
            type='number'
            name='priceFrom'
            value={filters.priceFrom}
            onChange={handleFilterChange}
            placeholder='Min'
          />
          <input
            type='number'
            name='priceTo'
            value={filters.priceTo}
            onChange={handleFilterChange}
            placeholder='Max'
          />
        </div>
        <form className='search-bar' onSubmit={handleSearch}>
          <input
            type='text'
            name='searchQuery'
            placeholder='Search orders...'
            value={filters.searchQuery}
            onChange={handleFilterChange}
          />
          <button type='submit'>Search</button>
        </form>
      </div>

      {/* Orders Table */}
      {loading ? (
        <div className='orders-table-skeleton'>
          {/* Render skeleton rows */}
          {[...Array(10)].map((_, index) => (
            <div key={index} className='skeleton-row'>
              {[...Array(7)].map((_, idx) => (
                <div key={idx} className='skeleton-cell'></div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <table className='orders-table'>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>User</th>
              <th>Total Amount</th>
              <th>Status</th>
              <th>Payment Method</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              const statusColor = orderStatuses.find(
                (os) => os.status === order.orderStatus,
              )?.color;
              return (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>{order.user?.email || 'Guest'}</td>
                  <td>
                    {order.orderItems?.[0]?.productSnapshot?.currency}
                    {order.totalAmount.toFixed(2)}
                  </td>
                  <td style={{ color: statusColor }}>{order.orderStatus}</td>
                  <td>{order.paymentMethod}</td>
                  <td>{new Date(order.createdAt).toLocaleString()}</td>
                  <td className='actions'>
                    <button
                      className='view'
                      onClick={() => navigateToOrderDetails(order.id)}
                    >
                      View
                    </button>
                    {/* <button
                      className='track'
                      onClick={() => navigateToAttachTracking(order.id)}
                    >
                      Track
                    </button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {/* Pagination */}
      <div className='pagination'>
        <button
          onClick={() => handlePageChange(filters.page - 1)}
          disabled={filters.page === 1}
        >
          Previous
        </button>
        <span>
          Page {filters.page} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(filters.page + 1)}
          disabled={filters.page === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AdminOrdersPage;
