import '../App.css';
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LandingPage from '../Pages/LandingPage/LandingPage';
import AffiliateProgram from '../Pages/AffiliateProgram/AffiliateProgram';
import FAQ from '../Pages/Learn/FAQ';
import AboutUs from '../Pages/Learn/AboutUs';
import Shop from '../Pages/Shop/Shop';
import Bundle from '../Pages/Bundle/Bundle';
import ContactUs from '../Pages/ContactUs/ContactUs';
import ChatBox from '../Pages/ChatBox/ChatBox';
import ProductDetailPage from '../Pages/Product/ProductDetailPage';
import CartPage from 'Pages/Testing/Cart/Cart';
import TermsConditions from '../Pages/TermsConditions/TermsConditions';
import PrivacyPolicy from 'Pages/PirvacyPolicy/PrivacyPolicy';
import CheckoutForm from 'Pages/Checkout/CheckoutForm';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from 'components/ScrollToTop';
import Blogs from 'Pages/Blogs/Blogs';
import NotFound from 'Pages/404 Page/NotFound';
import VideoOverlay from 'Pages/VideoOverlay/VideoOverlay';
import Loader from 'components/Loader/Loader';
import SubmitReview from 'Pages/SubmitReviewPage/SubmitReviewPage';
import Popup from 'Pages/Popup/Popup'; // Import Popup component

const PublicRoutes = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: 'ease-in-sine',
      delay: 100,
    });

    const isCheckoutOrCart =
      location.pathname === '/checkout-form' || location.pathname === '/cart';
    setIsLoading(isCheckoutOrCart);

    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [location]);

  useEffect(() => {
    // Show popup only on the `/` route and if it hasn't been shown before
    if (location.pathname === '/' && !localStorage.getItem('popupShown')) {
      setShowPopup(true);
      localStorage.setItem('popupShown', 'true'); // Mark popup as shown
    }
  }, [location]);

  const handleClosePopup = () => {
    setShowPopup(false); // Hide the popup when user closes it
  };

  const isCheckoutRoute = location.pathname === '/checkout-form';

  return (
    <>
      {/* Video overlay for landing page */}
      {location.pathname === '/' && <VideoOverlay />}
      {!isCheckoutRoute && <Header />}
      <div className="main-content relative">
        <ScrollToTop />
        {isLoading && <Loader />}
        {/* Display the popup */}
        {showPopup && <Popup onClose={handleClosePopup} />}
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/affiliate-program" element={<AffiliateProgram />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/bundle" element={<Bundle />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route
            path="/collections/products/:id"
            element={<ProductDetailPage />}
          />
          <Route path="/checkout-form" element={<CheckoutForm />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/submit-review" element={<SubmitReview />} />
        </Routes>
      </div>
      {!isCheckoutRoute && <ChatBox />}
      {!isCheckoutRoute && <Footer />}
    </>
  );
};

export default PublicRoutes;
