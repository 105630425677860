import axios from "axios";
import AxiosClient from "utilities/axiosClient";

const getChat = async (chatRoomId, admin) => {
  console.log(chatRoomId, admin);
  const response = await AxiosClient.get(`/chat/messages/${chatRoomId}`, {
    headers: {
      Authorization: `Bearer ${admin?.token}`,
    },
  });
  return response.data;
};

const getChatRooms = async () => {
  const response = await AxiosClient.get(`/chat/rooms`, {
    withCredentials: true,
  });
  return response.data;
};

const sendMessage = async (messageData) => {
  await AxiosClient.post(`/chat/messages`, messageData, {
    withCredentials: true,
  });
};

const trackOrder = async (orderId, email, chatRoomId) => {
  return await AxiosClient.get(`/track/orders`, {
    params: {
      orderId,
      email,
      chatRoomId,
    },
  });
};

export default {
  getChat,
  getChatRooms,
  sendMessage,
  trackOrder,
};
