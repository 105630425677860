import React from 'react';
import './Section2.css';

const Section2 = () => {
  return (
    <div className="section2-container">
      <div className="scrolling-text-container-bar">
        <div className="scrolling-text-bar">
          <span>All 6 Essential Electrolytes</span>
          <span>Better Hydration</span>
          <span>Zero Sugar</span>
          <span>Multi Vitamins</span>
          <span>All 6 Essential Electrolytes</span>
          <span>Better Hydration</span>
          <span>Zero Sugar</span>
          <span>Multi Vitamins</span>
        </div>
      </div>
    </div>
  );
};

export default Section2;
