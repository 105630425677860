import React, { useState } from "react";
import { toast } from "react-toastify";
import AxiosClient from "utilities/axiosClient";
import { useAdminAuth } from "../../../../contexts/AdminAuthContext";
import { AiOutlineDelete } from "react-icons/ai";

const AddProduct = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [benefits, setBenefits] = useState("");
  const [howToUse, setHowToUse] = useState("");
  const [nutritionInfo, setNutritionInfo] = useState("");
  const [stock, setStock] = useState("");
  const [files, setFiles] = useState([]);
  const [pricePKR, setPricePKR] = useState("");
  const [priceAED, setPriceAED] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { admin } = useAdminAuth();

  const fileInputRef = React.createRef();

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter((file) => {
      if (file.size > 5 * 1024 * 1024) {
        toast.error(`File "${file.name}" exceeds 5MB size limit.`);
        return false;
      }
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        toast.error(`File "${file.name}" must be a JPG or PNG image.`);
        return false;
      }
      return true;
    });
    const filePreviews = validFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFiles((prevFiles) => [...prevFiles, ...filePreviews]);
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => {
      const removedFile = prevFiles[index];
      URL.revokeObjectURL(removedFile.preview); // Release memory
      return prevFiles.filter((_, i) => i !== index);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!files.length) {
      toast.error("Please upload at least one product image.");
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("benefits", benefits);
    formData.append("howToUse", howToUse);
    formData.append("nutritionInfo", nutritionInfo);
    formData.append("stock", stock);
    formData.append(
      "prices",
      JSON.stringify([
        { currency: "PKR", price: pricePKR },
        { currency: "AED", price: priceAED },
      ])
    );
    files.forEach(({ file }) => formData.append("files", file));

    try {
      await AxiosClient.post("/products/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${admin.token}`,
        },
      });

      toast.success("Product added successfully!");
      setName("");
      setDescription("");
      setBenefits("");
      setHowToUse("");
      setNutritionInfo("");
      setStock("");
      setFiles([]);
      setPricePKR("");
      setPriceAED("");
      if (fileInputRef.current) fileInputRef.current.value = "";
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="add-product">
      <h2>Add New Product</h2>
      <form onSubmit={handleSubmit} className="add-product-form">
        <input
          type="text"
          placeholder="Product Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Price (PKR)"
          value={pricePKR}
          onChange={(e) => setPricePKR(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Price (AED)"
          value={priceAED}
          onChange={(e) => setPriceAED(e.target.value)}
          required
        />
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <textarea
          placeholder="Benefits"
          value={benefits}
          onChange={(e) => setBenefits(e.target.value)}
        />
        <textarea
          placeholder="How to Use"
          value={howToUse}
          onChange={(e) => setHowToUse(e.target.value)}
        />
        <textarea
          placeholder="Nutrition Info"
          value={nutritionInfo}
          onChange={(e) => setNutritionInfo(e.target.value)}
        />
        <input
          type="number"
          placeholder="Stock Quantity"
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          required
        />
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {files.length > 0 && (
          <div className="file-preview">
            <h4>Image Previews:</h4>
            <div className="grid grid-cols-3 gap-4">
              {files.map((fileObj, index) => (
                <div key={index} className="relative group">
                  <img
                    src={fileObj.preview}
                    alt="Preview"
                    className="w-full h-32 object-cover rounded shadow"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(index)}
                    className="absolute top-1 right-1 text-white text-xs px-2 py-1 rounded opacity-75 group-hover:opacity-100"
                  >
                    <AiOutlineDelete size={20} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Adding..." : "Add Product"}
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
