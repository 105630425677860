import React from "react";
import Section1 from "./Section 1/Section1";
import Section2 from "./Section 2/Section2";
import Section3 from "./Section 3/Section3";
import Section4 from "./Section 4/Section4";
import Section5 from "./Section 5/Section5";
import Section6 from "./Section 6/Section6";
import Section8 from "./Section 8/Section8";
import Section10 from "./Section 10/Section10";
import Section13 from "./Section 13/Section13";
import Section15 from "./Section 15/Section15";
import Section16 from "./Section 16/Section16";

import "./LandingPage.css";
import Section17 from "./Section 17/Section17";
import ParticlesComponent from "./Section 1/ParticlesComponent";

const LandingPage = () => {
  return (
    <>
      <section id="section1">
        <Section1 />
      </section>
    <main className="landing-page-container">
      <section id="section2">
        <Section2 />
      </section>
      <section id="section3">
        <Section3 />
      </section>
      <section id="section17">
        <Section17 />
      </section>
      <section id="section4">
        <Section4 />
      </section>
      <section id="section8">
        <Section8 />
      </section>
      <section id="section13">
        <Section13 />
      </section>
      <section id="section16">
        <Section16 />
      </section>
      <section id="section5">
        <Section5 />
      </section>
      <section id="section10">
        <Section10 />
      </section>
      <section id="section6">
        <Section6 />
      </section>
      <section id="section15">
        <Section15 />
      </section>
    </main>
    </>
  );
};

export default LandingPage;
