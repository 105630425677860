import React, { useState, useEffect } from 'react';
import { useChat } from '../../../contexts/ChatContext';
import ChatRoomList from '../../../components/Admin/Chat/ChatRoomList/ChatRoomList';
import ChatRoom from '../../../components/Admin/Chat/ChatRoom/ChatRoom';
import './Chat.css';
import { FiArrowLeft } from 'react-icons/fi';

const AdminChatPage = () => {
  const { getChatRooms } = useChat();
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    getChatRooms(); // Fetch chat rooms on load
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [getChatRooms]);

  const handleChatSelect = (chatRoomId) => {
    setSelectedChatRoom(chatRoomId);
  };
  const handleCloseChat = () => {
    setSelectedChatRoom(null);
  };

  return (
    <div className='admin-chat-container'>
    {!isMobileView || !selectedChatRoom ? (
      <ChatRoomList
        onSelectChat={handleChatSelect}
        selectedChatRoom={selectedChatRoom}
      />
    ) : null}

    {selectedChatRoom ? (
      <div className='chat-room'>
        {isMobileView && (
          <button className='close-chat-btn' onClick={handleCloseChat}>
          <FiArrowLeft size={20} /> 
        </button>
        )}
        <ChatRoom chatRoomId={selectedChatRoom} />
      </div>
    ) : !isMobileView ? (
      <div className='chat-room-select'>
        <p>Select a chat room to start a conversation.</p>
      </div>
    ) : null}
  </div>
  );
};

export default AdminChatPage;
