import React, { useState, useEffect } from 'react';
import {
  FaShoppingCart,
  FaClock,
  FaCheckCircle,
  FaUndo,
  FaDollarSign,
} from 'react-icons/fa';
import { MdPending } from 'react-icons/md';
import AxiosClient from 'utilities/axiosClient';
import './Dashboard.css';
import { useAdminAuth } from 'contexts/AdminAuthContext';

const Dashboard = () => {
  const { admin } = useAdminAuth();

  const [stats, setStats] = useState({
    totalOrders: 0,
    pendingOrders: 0,
    completedOrders: 0,
    returnedOrders: 0,
    newCustomers: 0,
    totalSales: 0,
    revenuePerCurrency: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const response = await AxiosClient.get('/orders/all/stats', {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        });
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return <div>Loading Dashboard...</div>;
  }

  return (
    <div className='flex h-screen'>
      {/* Main content area */}
      <div className='flex-1 bg-white p-6 overflow-y-auto'>
        <h1 className='text-3xl font-bold mb-8 text-gray-800'>Dashboard</h1>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {/* Cards */}
          <div className='bg-blue-500 text-white p-6 rounded-md shadow-md flex items-center'>
            <FaShoppingCart className='text-3xl mr-4' />
            <div>
              <h3 className='text-lg font-semibold mb-2'>Total Orders</h3>
              <p className='text-2xl'>{stats.totalOrders}</p>
            </div>
          </div>
          <div className='bg-yellow-400 text-white p-6 rounded-md shadow-md flex items-center'>
            <FaClock className='text-3xl mr-4' />
            <div>
              <h3 className='text-lg font-semibold mb-2'>Pending Orders</h3>
              <p className='text-2xl'>{stats.pendingOrders}</p>
            </div>
          </div>
          <div className='bg-green-500 text-white p-6 rounded-md shadow-md flex items-center'>
            <FaCheckCircle className='text-3xl mr-4' />
            <div>
              <h3 className='text-lg font-semibold mb-2'>Completed Orders</h3>
              <p className='text-2xl'>{stats.completedOrders}</p>
            </div>
          </div>
          <div className='bg-red-500 text-white p-6 rounded-md shadow-md flex items-center'>
            <FaUndo className='text-3xl mr-4' />
            <div>
              <h3 className='text-lg font-semibold mb-2'>Returned Orders</h3>
              <p className='text-2xl'>{stats.returnedOrders}</p>
            </div>
          </div>
          <div className='bg-indigo-500 text-white p-6 rounded-md shadow-md flex items-center'>
            <MdPending className='text-3xl mr-4' />
            <div>
              <h3 className='text-lg font-semibold mb-2'>New Customers</h3>
              <p className='text-2xl'>{stats.newCustomers}</p>
            </div>
          </div>
          {stats.revenuePerCurrency.map((rev) => (
            <div
              key={rev.currency}
              className='bg-purple-500 text-white p-6 rounded-md shadow-md flex items-center'
            >
              <FaDollarSign className='text-3xl mr-4' />
              <div>
                <h3 className='text-lg font-semibold mb-2'>
                  Revenue ({rev.currency})
                </h3>
                <p className='text-2xl'>
                  {rev.currency} {rev.totalRevenue.toFixed(2)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
