import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apple from "../../../assets/6LYETS_RENDER_APPLE.png";
import lime from "../../../assets/6LYETS_RENDER_LIME.png";
import orange from "../../../assets/6LYETS_RENDER_ORANGE.png";
import watermelon from "../../../assets/6LYETS_RENDER_WATERMELON.png";
import "./Section1.css";
import { TypeAnimation } from "react-type-animation";
import ParticlesComponent from "./ParticlesComponent";

const ImageList = [
  { id: 1, img: apple, title: "Apple", description: "" },
  { id: 2, img: lime, title: "Lime", description: "" },
  { id: 3, img: orange, title: "Orange", description: "" },
  { id: 4, img: watermelon, title: "Watermelon", description: "" },
];

const Section1 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % ImageList.length);
    }, 6000); // Change the image every 6 seconds

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    navigate("/shop");
  };

  return (
    <div className="hero-container">
      <ParticlesComponent/>
      <div className="grid-container">
        <div className="text-content">
          <h1 className="hero-title">
            New Arrival
            <br />
            Products
            <br />
            <TypeAnimation
              sequence={[
                "Apple",
                5000,
                "Lime",
                5000,
                "Orange",
                5000,
                "Watermelon",
                5000,
              ]}
              wrapper="span"
              speed={20}
              style={{
                fontSize: "3rem",
                display: "inline-block",
                color: "white",
              }}
              repeat={Infinity}
            />
          </h1>
          <p className="hero-description">
            {ImageList[currentIndex].description}
          </p>
          <div className="hero-button">
            <button onClick={handleClick} className="order-btn">
              Shop Now
            </button>
          </div>
        </div>
        <div className="image-section-hero">
          {ImageList.map((image, index) => (
            <img
              key={image.id}
              src={image.img}
              alt={image.title}
              className={`hero-image ${
                index === currentIndex
                  ? "active"
                  : index === (currentIndex + 1) % ImageList.length
                  ? "blur"
                  : ""
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section1;
