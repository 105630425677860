import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import amexIcon from "../assets/amex.png";
import applePayIcon from "../assets/apple-pay.png";
import mastercardIcon from "../assets/mastercard.png";
import visaIcon from "../assets/visa.png";
import logo from "../assets/logo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="page-container">
      <div className="main-content">
        {/* Your main page content goes here */}
      </div>
      <footer className="new-footer">
        <div className="footer-wrapper">
          {/* Company Info and Logo */}
          <div className="footer-section company-info">
            <img src={logo} alt="Company Logo" className="footer-logo" />
            <p className="footer-company-text">
            6LYETS L.L.C-FZ  <br />
            </p>
          </div>

          {/* Customer Links */}
          <div className="footer-section links">
            <ul className="footer-links">
              <li>
                <a href="/about-us">About us</a>
              </li>
              <li>
                <a href="/contact-us">Contact us</a>
              </li>
              <li>
                <a href="/terms-conditions">Terms & conditions</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/blogs">Blogs</a>
              </li>
            </ul>
          </div>

          {/* Social Media and Payment */}
          <div className="footer-section social-payment">
            <div className="social-icons">
              <a
                href="https://www.facebook.com/profile.php?id=61565760357604&mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.instagram.com/6lyets?igsh=MWpndHUzeHJscno3"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://www.tiktok.com/@6lyets_?_t=8rLYoiO6L1b&_r=1"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="TikTok"
              >
                <FontAwesomeIcon icon={faTiktok} />
              </a>
            </div>

            <div className="payment-icons">
              <img src={amexIcon} alt="AMEX" />
              <img src={applePayIcon} alt="Apple Pay" />
              <img src={mastercardIcon} alt="Mastercard" />
              <img src={visaIcon} alt="Visa" />
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom">
          <p>&copy; 2024 6LYETS. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
