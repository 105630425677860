import React, { createContext, useState, useContext, useCallback } from 'react';
import io from 'socket.io-client';
import chatService from '../services/chatServices';
import { useAdminAuth } from './AdminAuthContext';

const ChatContext = createContext();
export const socket = io(process.env.REACT_APP_SOCKET_SERVER);
export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const [chats, setChats] = useState([]);
  const { admin } = useAdminAuth();

  const sendMessage = async (text, senderType, chatRoomId, fileIds) => {
    const messageData = { chatRoomId, text, sender: senderType, fileIds };
    await chatService.sendMessage(messageData);
  };

  const getChat = async (id) => {
    const response = await chatService.getChat(id, admin);
    // Sort messages by `createdAt` to ensure latest messages are at the bottom
    response?.messages?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));
    return response;
  };

  const getChatRooms = useCallback(async () => {
    const rooms = await chatService.getChatRooms();
    setChats(rooms);
  }, []);

  const trackOrder = async (orderId, email, chatRoomId) => {
    try {
      const response = await chatService.trackOrder(orderId, email, chatRoomId);
      return response.data;
    } catch (error) {
      console.error('Error tracking order:', error);
      return null;
    }
  };

  return (
    <ChatContext.Provider
      value={{
        chats,
        trackOrder,
        sendMessage,
        getChat,
        getChatRooms,
        socket,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
