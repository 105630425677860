import React, { useState, useEffect } from 'react';
import AxiosClient from 'utilities/axiosClient';
import './Affiliate.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AffiliateAdmin = () => {
  const [applications, setApplications] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    fetchApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search]);

  const fetchApplications = async () => {
    try {
      const response = await AxiosClient.get('/affiliate/applications', {
        params: { search, page, pageSize },
        // Include authentication headers if required
      });
      setApplications(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching applications:', error);
      toast.error('Failed to fetch applications', {
        position: 'top-center',
        autoClose: 5000,
      });
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to first page on new search
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Function to truncate text after a certain number of words
  const truncateText = (text, maxWords = 5) => {
    if (!text) return '';
    const words = text.split(' ');
    return words.length > maxWords
      ? words.slice(0, maxWords).join(' ') + '...'
      : text;
  };

  return (
    <div className='affiliate-admin-container'>
      <h2>Affiliate Applications</h2>
      <div className='search-bar'>
        <input
          type='text'
          placeholder='Search applications'
          value={search}
          onChange={handleSearchChange}
        />
      </div>
      <div className='table-wrapper'>
        <table className='applications-table'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Topics</th>
              <th>About</th>
              <th>Instagram</th>
              <th>Facebook</th>
              <th>TikTok</th>
              <th>YouTube</th>
              <th>Submitted At</th>
            </tr>
          </thead>
          <tbody>
            {applications.map((app) => (
              <tr key={app.id}>
                <td data-label='Name'>{app.name}</td>
                <td data-label='Email'>{app.email}</td>
                <td data-label='Phone'>{app.phone}</td>
                <td data-label='Topics'>{truncateText(app.topics)}</td>
                <td data-label='About'>
                  <div className='tooltip'>
                    {truncateText(app.about)}
                    {app.about && app.about.split(' ').length > 5 && (
                      <span className='tooltiptext'>{app.about}</span>
                    )}
                  </div>
                </td>
                <td data-label='Instagram'>
                  {app.instagramLink ? (
                    <a
                      href={app.instagramLink}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='link-available'
                    >
                      Link
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
                <td data-label='Facebook'>
                  {app.facebookLink ? (
                    <a
                      href={app.facebookLink}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='link-available'
                    >
                      Link
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
                <td data-label='TikTok'>
                  {app.tiktokLink ? (
                    <a
                      href={app.tiktokLink}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='link-available'
                    >
                      Link
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
                <td data-label='YouTube'>
                  {app.youtubeLink ? (
                    <a
                      href={app.youtubeLink}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='link-available'
                    >
                      Link
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
                <td data-label='Submitted At'>
                  {new Date(app.createdAt).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className='pagination'>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`page-button ${page === index + 1 ? 'active' : ''}`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default AffiliateAdmin;
