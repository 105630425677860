import React, { useState } from 'react';
import {
  FaChevronDown,
  FaChevronUp,
  FaInfoCircle,
  FaHeart,
  FaStar,
  FaUtensils,
} from 'react-icons/fa';

const Accordion = ({ product }) => {
  const [openAccordions, setOpenAccordions] = useState([0]);
  const toggleAccordion = (index) => {
    setOpenAccordions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  const accordionData = [
    {
      title: 'Description',
      icon: <FaInfoCircle className='text-blue-500 mr-2' />,
      content: product.description,
    },
    {
      title: 'Benefits',
      icon: <FaHeart className='text-pink-500 mr-2' />,
      content: product.benefits,
    },
    {
      title: 'How to Use',
      icon: <FaUtensils className='text-green-500 mr-2' />,
      content: product.howToUse,
    },
    {
      title: 'Nutritions',
      icon: <FaStar className='text-yellow-500 mr-2' />,
      content: product.nutritionInfo,
    },
  ];

  return (
    <div className='mt-6 shadow-lg rounded-lg bg-white'>
      {accordionData.map((item, index) => (
        <div key={index} className='border-t border-gray-200'>
          <div
            className='flex items-center justify-between p-4 cursor-pointer hover:bg-gray-100 transition-colors duration-200'
            onClick={() => toggleAccordion(index)}
          >
            <div className='flex items-center'>
              {item.icon}
              <span className='text-lg font-semibold text-gray-700'>
                {item.title}
              </span>
            </div>
            {openAccordions.includes(index) ? (
              <FaChevronUp className='text-gray-700 transition-transform duration-200 ease-in-out' />
            ) : (
              <FaChevronDown className='text-gray-700 transition-transform duration-200 ease-in-out' />
            )}
          </div>
          <div
            className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${
              openAccordions.includes(index) ? 'max-h-screen' : 'max-h-0'
            }`}
          >
            <div className='p-4 text-gray-600 whitespace-pre-line'>
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
