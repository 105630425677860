import React from 'react'
import {
    FiDroplet,
    FiCpu,
    FiThermometer,
    FiZap,
  } from "react-icons/fi";
import './Section17.css'

const benefits = [
    { icon: <FiCpu />, text: "Cognitive Boost" },
    { icon: <FiThermometer />, text: "Thermal Relief" },
    { icon: <FiDroplet />, text: "Dehydration Defense" },
    { icon: <FiZap />, text: "Energy Renewal" },
  ];
const Section17 = () => {
  return (
    <section data-aos-once="true" data-aos="fade-down" className="section17-container">
        <div className="section17-info">
            <div className='section17-text'>
            <h1 className='section17-heading'>Reasons to love it</h1>
            <p className='section17-p'>Feel the benefits. Crave the taste.</p>

            </div>
          <div className="section17-benefits">
            {benefits.map((benefit, index) => (
              <div key={index} className="section17-benefit-item">
                <span className="section17-benefit-icon">
                  {benefit.icon}
                </span>
                <span className="section17-benefit-text">{benefit.text}</span>
              </div>
            ))}
          </div>
      </div>
    </section>
  )
}

export default Section17
