import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProductCard = ({ imageUrl, name, price, productId, currency }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/collections/products/${productId}`);
  };

  return (
    <div
      className='bg-white rounded-lg text-center  hover:shadow-xl cursor-pointer'
      onClick={handleNavigate}
    >
      <div className='flex justify-center items-center mb-4'>
        <img
          src={imageUrl}
          alt={name}
          className='w-auto h-auto object-cover rounded-md'
        />
      </div>
      <h3 className='text-lg font-semibold text-gray-700'>{name}</h3>
      <p className='text-lg text-gray-600'>
        {currency} {price}
      </p>
    </div>
  );
};

export default ProductCard;
