import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import './Topbar.css';
import { FiUser } from 'react-icons/fi';
import { useAdminAuth } from '../../../contexts/AdminAuthContext';
import { AiOutlineMenu } from 'react-icons/ai';

const Topbar = ({ toggleSidebar, isSidebarOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useAdminAuth();

  const handleProfileClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <nav
      className={`topbar ${isSidebarOpen ? 'sidebar-closed' : 'sidebar-open'}`}
    >
      <div className='hamburger-icon' onClick={toggleSidebar}>
        <AiOutlineMenu size={24} />
      </div>
      <div className='topbar-title'>
        {/* Add title or logo here if needed */}
      </div>
      <div className='profile' onClick={handleProfileClick}>
        <FiUser className='profileIcon' />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          disableScrollLock
        >
          <MenuItem onClick={handleClose}>View Profile</MenuItem>
          <MenuItem onClick={handleClose}>Account Settings</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </nav>
  );
};

export default Topbar;
