import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AxiosClient from "utilities/axiosClient";
import "../AddProduct/AddProduct.css";
import { useAdminAuth } from "../../../../contexts/AdminAuthContext";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const EditProduct = () => {
  const navigate = useNavigate();

  const { admin } = useAdminAuth();
  const { id } = useParams(); // Get the product ID from the URL
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [benefits, setBenefits] = useState("");
  const [howToUse, setHowToUse] = useState("");
  const [nutritionInfo, setNutritionInfo] = useState("");
  const [stock, setStock] = useState("");
  const [files, setFiles] = useState([]);
  const [pricePKR, setPricePKR] = useState("");
  const [priceAED, setPriceAED] = useState("");

  // Fetch product details
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const userTimezone = dayjs.tz.guess();
        const response = await AxiosClient.get(`/products/${id}`, {
          params: {
            timezone: userTimezone,
          },
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        });

        const product = response.data;

        console.log(response.data)

        console.log(response.data);

        // Populate form fields with fetched product data
        setName(product.name);
        setDescription(product.description);
        setBenefits(product.benefits || "");
        setHowToUse(product.howToUse || "");
        setNutritionInfo(product.nutritionInfo || "");
        setStock(product.stock);
        setPricePKR(
          product.productPrices.find((p) => p.currency === "PKR")?.price || ""
        );
        setPriceAED(
          product.productPrices.find((p) => p.currency === "AED")?.price || ""
        );
      } catch (error) {
        console.error("Error fetching product data:", error);
        alert("Failed to fetch product details.");
      }
    };

    fetchProduct();
  }, [id, admin.token]);

  // Update product details
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("benefits", benefits);
    formData.append("howToUse", howToUse);
    formData.append("nutritionInfo", nutritionInfo);
    formData.append("stock", stock);
    formData.append(
      "prices",
      JSON.stringify([
        { currency: "PKR", price: pricePKR },
        { currency: "AED", price: priceAED },
      ])
    );
    files.forEach((file) => formData.append("files", file));

    try {
      await AxiosClient.put(`/products/edit/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${admin.token}`,
        },
      });
      alert("Product updated successfully.");
      navigate("/admin/secret-dashboard/products"); // Navigate to the specified page
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Failed to update product.");
    }
  };

  return (
    <div className="add-product">
      <h2>Edit Product</h2>
      <form onSubmit={handleSubmit} className="add-product-form">
        <input
          type="text"
          placeholder="Product Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Price (PKR)"
          value={pricePKR}
          onChange={(e) => setPricePKR(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Price (AED)"
          value={priceAED}
          onChange={(e) => setPriceAED(e.target.value)}
          required
        />
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <textarea
          placeholder="Benefits"
          value={benefits}
          onChange={(e) => setBenefits(e.target.value)}
        />
        <textarea
          placeholder="How to Use"
          value={howToUse}
          onChange={(e) => setHowToUse(e.target.value)}
        />
        <textarea
          placeholder="Nutrition Info"
          value={nutritionInfo}
          onChange={(e) => setNutritionInfo(e.target.value)}
        />
        <input
          type="number"
          placeholder="Stock Quantity"
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          required
        />
        <input
          type="file"
          multiple
          onChange={(e) => setFiles(Array.from(e.target.files))}
        />
        <button type="submit">Update Product</button>
      </form>
    </div>
  );
};

export default EditProduct;
