import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  AiOutlineShopping,
  AiOutlineDown,
  AiOutlineMenu,
} from 'react-icons/ai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-regular-svg-icons';
import {
  faBoxOpen,
  faShop,
  faTruckFast,
  faArrowRightFromBracket,
  faChartLine,
  faContactCard,
} from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const toggleProducts = () => setIsProductsOpen(!isProductsOpen);
  const toggleAnalytics = () => setIsAnalyticsOpen(!isAnalyticsOpen);

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/login');
  };

  const handleLinkClick = () => {
    if (isMobile && isOpen) {
      toggleSidebar(); 
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 426);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
       {/* {isMobile && (
        <div className="hamburger-menu" onClick={toggleSidebar}>
          <AiOutlineMenu size={30} />
        </div>
      )} */}
      <aside
        className={`sidebar ${isOpen ? 'open' : 'closed'} ${
          isMobile ? 'mobile' : ''
        }`}
      >
        <a href="#" className="brand">
          {isOpen && <span className="text">6LYETS ADMIN</span>}
        </a>
        <ul className="menu top">
          <li>
            <NavLink
              to="/admin/secret-dashboard"
              activeClassName="active"
              onClick={handleLinkClick}
            >
              <FontAwesomeIcon icon={faChartLine} className="dashboard-icon" />
              {isOpen && <span className="text">Dashboard</span>}
            </NavLink>
          </li>
          <li>
            <div className="menu-item" onClick={toggleProducts}>
              <FontAwesomeIcon icon={faShop} className="products-icon" />
              {isOpen && <span className="text">My Products</span>}
              {isOpen && (
                <AiOutlineDown
                  className={`dropdown-icon ${isProductsOpen ? 'rotated' : ''}`}
                />
              )}
            </div>
            {isProductsOpen && isOpen && (
              <ul className="submenu">
                <li>
                  <NavLink
                    to="/admin/secret-dashboard/products/add"
                    onClick={handleLinkClick}
                  >
                    <span className="text">Add Product</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/secret-dashboard/products"
                    onClick={handleLinkClick}
                  >
                    <span className="text">View Products</span>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div className="menu-item" onClick={toggleAnalytics}>
              <FontAwesomeIcon icon={faBoxOpen} className="orders-icon" />
              {isOpen && <span className="text">Orders</span>}
              {isOpen && (
                <AiOutlineDown
                  className={`dropdown-icon ${
                    isAnalyticsOpen ? 'rotated' : ''
                  }`}
                />
              )}
            </div>
            {isAnalyticsOpen && isOpen && (
              <ul className="submenu">
                <li>
                  <NavLink
                    to="/admin/secret-dashboard/orders"
                    onClick={handleLinkClick}
                  >
                    <span className="text">View Orders</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/analytics/reports"
                    onClick={handleLinkClick}
                  >
                    <span className="text">Reports</span>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
        <ul className="menu">
          <li>
            <NavLink
              to="/admin/secret-dashboard/live-chat"
              onClick={handleLinkClick}
            >
              <FontAwesomeIcon icon={faMessage} className="chat-icon" />
              {isOpen && <span className="text">Live Chat</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/secret-dashboard/tracking"
              onClick={handleLinkClick}
            >
              <FontAwesomeIcon icon={faTruckFast} className="settings-icon" />
              {isOpen && <span className="text">Tracking</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/secret-dashboard/contactus"
              onClick={handleLinkClick}
            >
              <FontAwesomeIcon
                icon={faContactCard}
                className="fa fa-address-book"
              />
              {isOpen && <span className="text">Contact-Us</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/secret-dashboard/affiliate-programs"
              onClick={handleLinkClick}
            >
              <FontAwesomeIcon
                icon={faContactCard}
                className="fa fa-address-book"
              />
              {isOpen && <span className="text">Affiliate Applications</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/login"
              className="logout"
              onClick={() => {
                handleLogout();
                handleLinkClick();
              }}
            >
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className="logout-icon"
              />
              {isOpen && <span className="text">Logout</span>}
            </NavLink>
          </li>
        </ul>
        <div className="bottomLine"></div>
      </aside>
    </>
  );
};


export default Sidebar;
