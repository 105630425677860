import React from "react";
import {
  FaShippingFast,
  FaStar,
  FaUndo,
  FaHeadphonesAlt,
} from "react-icons/fa";
import "./Section10.css";

const ServiceData = [
  {
    id: 1,
    icon: <FaShippingFast className="icon" />,
    title: "Free Premium Shipping",
    description: "Enjoy complimentary shipping on all orders over $100",
  },
  {
    id: 2,
    icon: <FaStar className="icon" />,
    title: "Quality Guarantee",
    description: "We stand behind the excellence of every product we offer",
  },
  {
    id: 3,
    icon: <FaUndo className="icon" />,
    title: "Easy Returns",
    description: "Hassle-free 30-day returns for your peace of mind",
  },
  {
    id: 4,
    icon: <FaHeadphonesAlt className="icon" />,
    title: "24/7 Support",
    description: "Dedicated Support",
  },
];

const Section10 = () => {
  return (
    <div className="section10-container">
      {/* Section Heading */}
      <h1
        data-aos="fade-down"
        data-aos-once="true"
        className="section10-heading"
      >
        Why Choose 6LYETS
      </h1>

      {/* Services Grid */}
      <div className="service-grid">
        {ServiceData.map((data) => (
          <div key={data.id} className="service-item">
            <div
              data-aos="fade-down"
              data-aos-once="true"
              className="icon-wrapper"
            >
              {data.icon}
            </div>
            <h1
              data-aos="fade-down"
              data-aos-once="true"
              className="service-title"
            >
              {data.title}
            </h1>
            <p
              data-aos="fade-down"
              data-aos-once="true"
              className="service-description"
            >
              {data.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section10;
