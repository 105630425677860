import React, { useEffect } from 'react';
import { useChat } from '../../../../contexts/ChatContext';
import { FaUserCircle } from 'react-icons/fa';
import './ChatRoomList.css';

const ChatRoomList = ({ onSelectChat, selectedChatRoom }) => {
  const { chats, getChatRooms } = useChat();

  useEffect(() => {
    getChatRooms(); // Fetch all chat rooms when the component mounts
  }, [getChatRooms]);

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const messageDate = new Date(timestamp);
    const dayDifference = Math.floor(
      (now - messageDate) / (1000 * 60 * 60 * 24),
    );

    if (dayDifference < 7) {
      return messageDate.toLocaleDateString('en-US', { weekday: 'long' });
    } else {
      return messageDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
    }
  };

  return (
    <div className='chat-room-list'>
      <h2>Chat Rooms</h2>
      {chats.chatRooms?.map((chat) => (
        <div
          key={chat.chatRoomId}
          className={`chat-room-item ${
            selectedChatRoom === chat.chatRoomId ? 'active' : ''
          }`}
          onClick={() => onSelectChat(chat.chatRoomId)}
        >
          <FaUserCircle size={24} className='user-icon' />
          <div className='chat-info'>
            <span className='session-id'>{chat.sessionId.slice(0, 8)}...</span>
            <span className='last-message'>{chat.lastMessage}</span>
            <span className='last-message-time'>
              {chat.lastMessageTimeStamp &&
                formatTimestamp(chat.lastMessageTimeStamp)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};


export default ChatRoomList;
