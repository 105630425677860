import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import hydrate from '../../../assets/hydrate.webp';
import ProductCard from '../../../components/ProductCard';
import AxiosClient from 'utilities/axiosClient';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const RecomendedProducts = ({ currentProductId }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const userTimezone = dayjs.tz.guess();
        const response = await AxiosClient.get('/products', {
          params: {
            timezone: userTimezone,
          },
        });
        console.log('response.data', response.data);
        console.log('currentProductId', currentProductId);
        if (response.data) {
          // Filter out the current product
          const filteredProducts = response.data.filter(
            (product) => product.id !== parseInt(currentProductId),
          );
          console.log('filteredProducts', filteredProducts);
          setProducts(filteredProducts);
        } else {
          throw new Error('No products found');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [currentProductId]);

  return (
    <div className='flex flex-col items-center mx-auto px-6 md:px-12 py-12 bg-gray-50 rounded-lg'>
      <h2 className='text-2xl md:text-3xl font-semibold text-gray-800 mb-6 text-center'>
        You may also like
      </h2>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full'>
        {products.map((product) => (
          <ProductCard
            key={product.id}
            imageUrl={
              product.productImages[0]?.file?.url || hydrate // Fallback image
            }
            name={product.name}
            price={`${product.currency} ${product.price?.toFixed(2)}`}
            productId={product.id}
          />
        ))}
      </div>
    </div>
  );
};

export default RecomendedProducts;
