import React, { useState } from 'react';
import axios from 'axios';
import './ContactUs.css';
import contactImage from '../../assets/ContactUs.png';
import AxiosClient from 'utilities/axiosClient';
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await AxiosClient.post('/contact', formData);
      toast.success(response.data.message); // Show success toast
      setFormData({ fullName: '', email: '', message: '' });
    } catch (error) {
      toast.error('Failed to submit message'); // Show error toast
    }
  };

  return (
    <div className='contact-container'>
      <div data-aos-once='true' data-aos='fade-up' className='form-section'>
        <h2>Drop us a line</h2>
        <form onSubmit={handleSubmit}>
          <div className='input-group'>
            <label htmlFor='fullName'>Name</label>
            <input
              type='text'
              id='fullName'
              placeholder="What's your full name?"
              required
              value={formData.fullName}
              onChange={handleChange}
            />
          </div>
          <div className='input-group'>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              id='email'
              placeholder='you@example.com'
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className='input-group'>
            <label htmlFor='message'>Note</label>
            <textarea
              id='message'
              placeholder='Write your message for the team here'
              value={formData.message}
              required
              onChange={handleChange}
            ></textarea>
          </div>
          <button className='buttonContactUs' type='submit'>
            Submit
          </button>
        </form>
      </div>
      <div data-aos-once='true' data-aos='zoom-out' className='image-section'>
        <img src={contactImage} alt='Contact illustration' />
      </div>

      {/* Toast container to render toasts */}
      <ToastContainer position='top-right' autoClose={5000} />
    </div>
  );
};

export default Contact;
