import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/logo.png";
import { IoBagOutline, IoMenu, IoClose } from "react-icons/io5";
import { FaTruck } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import CartSlider from "./CartSlider";
import "./Header.css";
import { useCart } from '../contexts/CartContext';

const Header = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false); 
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const { toggleCart, updateOrCreateCart } = useCart();
  const location = useLocation(); // Get current location

  const handleNavigation = (path) => {
    setDropdownOpen(false);
    setMobileMenuOpen(false);
    navigate(path);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const _toggleCart = () => {
    toggleCart();
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === '/') {
        setScrolled(window.scrollY > 50);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]); // Dependency on location.pathname

  useEffect(() => {
    // Reset isScrolled whenever the pathname changes
    if (location.pathname !== '/') {
      setScrolled(false);
    }
  }, [location.pathname]); // Reset when navigating away from landing page

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="free-shipping">
        <div className="scrolling-text">
          <span>ARE FREE SHIPPING ON ORDERS OVER DHS. 100.00</span>
        </div>
      </div>
      <header className={`header ${isScrolled ? "scrolled" : ""}`}>
        <div className="header-container-part-one">
          <div className="logo" onClick={() => handleNavigation("/")}>
            <img src={logo} alt="logo" height="40" width="80" />
          </div>
          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <IoClose size={32} /> : <IoMenu size={32} />}
          </div>
          <div className={`menu-header ${isMobileMenuOpen ? "show" : ""}`}>
            <p onClick={() => handleNavigation("/shop")}>Shop</p>
            <p onClick={() => handleNavigation("/affiliate-program")}>
              Community
            </p>
            <div className="dropdown" ref={dropdownRef}>
              <p className="dropdown-trigger" onClick={toggleDropdown}>
                Learn
              </p>
              {isDropdownOpen && (
                <div className={`dropdown-menu ${isDropdownOpen ? "dropdown-menu-show" : ""}`}>
                  <p onClick={() => handleNavigation("/faq")}>FAQ</p>
                  <p onClick={() => handleNavigation("/about-us")}>About Us</p>
                </div>
              )}
            </div>
            <p onClick={() => handleNavigation("/contact-us")}>Contact us</p>
          </div>
          <div className="cart-icon" onClick={_toggleCart}>
            <IoBagOutline size={32} />
          </div>
        </div>
      </header>
      <CartSlider isOpen={isCartOpen} onClose={_toggleCart} />
    </div>
  );
};

export default Header;
