import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AxiosClient from 'utilities/axiosClient';
import { useAdminAuth } from 'contexts/AdminAuthContext';
import AttachTrackingIdForm from '../EditOrder/EditOrder';
import './OrderDetails.css';

const OrderDetails = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const { admin } = useAdminAuth();
  const [loading, setLoading] = useState(true);

  // Fetch the order details
  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      try {
        const response = await AxiosClient.get(`/orders/${orderId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${admin.token}`,
          },
        });
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId]);

  if (loading) {
    return (
      <div className='order-details'>
        {/* Skeleton loading components */}
        <div className='skeleton-header'></div>
        <div className='skeleton-info'></div>
        {/* ...more skeletons as needed */}
      </div>
    );
  }

  return (
    <div className='order-details'>
      <h1>Order Details - ID {order?.id}</h1>

      {/* User Information */}
      <h2>User Information</h2>
      <div className='user-info'>
        <p>
          <strong>Email:</strong> {order.user?.email || 'Guest'}
        </p>
        <p>
          <strong>Name:</strong> {order.user?.firstName} {order.user?.lastName}
        </p>
        <p>
          <strong>Phone:</strong> {order.user?.phoneNumber || 'N/A'}
        </p>
      </div>

      {/* Order Details */}
      <h2>Order Summary</h2>
      <p>
        <strong>Total Amount:</strong>{' '}
        {order?.orderItems?.[0]?.productSnapshot?.currency} {order.totalAmount}
      </p>
      <p>
        <strong>Status:</strong> {order.orderStatus}
      </p>
      <p>
        <strong>Payment Method:</strong> {order.paymentMethod}
      </p>
      <p>
        <strong>Tracking ID:</strong> {order.trackingId || 'N/A'}
      </p>

      {/* Tracking Information */}
      {order.trackingInfo?.length > 0 && (
        <div>
          <h2>Tracking Information</h2>
          <table className='tracking-table'>
            <thead>
              <tr>
                <th>Carrier</th>
                <th>Status</th>
                <th>Location</th>
                <th>Estimated Delivery From</th>
                <th>Estimated Delivery To</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              {order.trackingInfo.map((info) => (
                <tr key={info.id}>
                  <td>{info.carrier || 'N/A'}</td>
                  <td>{info.status}</td>
                  <td>{info.location || 'N/A'}</td>
                  <td>{info.estimatedDeliveryFrom || 'N/A'}</td>
                  <td>{info.estimatedDeliveryTo || 'N/A'}</td>
                  <td>{new Date(info.dateUpdated).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Order Items */}
      <h2>Order Items</h2>
      <table className='order-items-table'>
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Price ({order.orderItems[0].productSnapshot.currency})</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {order.orderItems.map((item) => (
            <tr key={item.id}>
              <td>{item.productSnapshot.name}</td>
              <td>{item.productSnapshot.price.toFixed(2)}</td>
              <td>{item.quantity}</td>
              <td>{(item.productSnapshot.price * item.quantity).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Form to Attach Tracking ID */}
      {/* {!order.trackingId ? ( */}
        <div className='tracking-id-form'>
          <h2>Update Tracking ID</h2>
          <AttachTrackingIdForm
            orderId={order?.id}
            currentTrackingId={order?.trackingId}
          />
        </div>
      {/* ) : (
        <p>
          <strong>Tracking ID:</strong> {order.trackingId}
        </p>
      )} */}
    </div>
  );
};

export default OrderDetails;
