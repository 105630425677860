import React, { useState } from "react";
import { FiEdit, FiTrash } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AxiosClient from "utilities/axiosClient";
import { toast } from "react-toastify";

const ProductCard = ({ imageUrl, name, price, id, onProductDeleted, currency }) => {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleEdit = () =>
    navigate(`/admin/secret-dashboard/products/edit/${id}`);

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      setIsDeleting(true);
      try {
        await AxiosClient.delete(`/products/${id}`);
        toast.success("Product deleted successfully!");
        onProductDeleted(id);
      } catch (error) {
        console.error("Error deleting product:", error);
        toast.error("An error occurred while deleting the product.");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <div className='relative bg-white rounded-lg text-center hover:shadow-xl cursor-pointer p-4 border-2 border-slate-300 max-w-xs mx-auto sm:max-w-sm md:max-w-md lg:max-w-none'>
      <div className='flex justify-center items-center mb-4'>
        <img
          src={imageUrl || 'default-image.jpg'}
          alt={name}
          className='w-full h-48 object-cover rounded-md'
        />
      </div>
      <h3 className='text-lg font-semibold text-gray-700'>{name}</h3>
      <p className='text-lg text-gray-600'>
        {currency} {price}
      </p>
      <div className='flex gap-4 justify-center mt-4'>
        <button
          onClick={handleEdit}
          className='flex items-center gap-2 bg-blue-500 text-white px-3 py-1 text-sm rounded-full hover:bg-blue-600'
        >
          <FiEdit size={16} /> Edit
        </button>
        <button
          onClick={handleDelete}
          disabled={isDeleting}
          className={`flex items-center gap-2 px-3 py-1 text-sm rounded-full ${
            isDeleting
              ? 'bg-gray-400'
              : 'bg-red-500 hover:bg-red-600 text-white'
          }`}
        >
          <FiTrash size={16} /> {isDeleting ? 'Deleting...' : 'Delete'}
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
