import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import hydrate from "../../../../assets/hydrate.webp"; // Fallback image
import Product from "../../../../Pages/Product/Product"; // Assuming Product is in components
import { useParams } from "react-router-dom";
import AxiosClient from "utilities/axiosClient";
import ProductCard from "components/ProductCard";

const fallbackProduct = {
  name: "Hydrate Discovery Pack",
  price: 75.0,
  description:
    "Zero-sugar blend of 6 essential electrolytes, 4 vitamins, and Zinc.",
  benefits: "Boosts hydration with zero sugar and essential vitamins.",
  howToUse: "Mix with water and consume before or after a workout.",
  nutritionInfo: "Contains electrolytes, vitamins, and no added sugar.",
  stock: 10,
  productImages: [
    { imageUrl: hydrate },
    { imageUrl: hydrate },
    { imageUrl: hydrate },
  ],
};

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  // Fetch product details
  const fetchProduct = useCallback(async () => {
    try {
      const response = await AxiosClient.get(`/products/${id}`);
      if (response.data) {
        setProduct(response.data);
      } else {
        setProduct(fallbackProduct);
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      setProduct(fallbackProduct); // Use fallback in case of error
    }
  }, [id]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  if (!product) {
    return <p>Loading product details...</p>;
  }

  // Render the Product component with the fetched or fallback product data
  
  return <Product product={product} />;
};

export default ProductDetail;
