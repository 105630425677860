import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import AxiosClient from 'utilities/axiosClient';
import './SubmitReview.css'; // Ensure this imports your Tailwind setup

const SubmitReview = () => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [product, setProduct] = useState(null); // Product state
  const [isValid, setIsValid] = useState(null); // Token validation state
  const [searchParams] = useSearchParams();

  // Extract token and productId from URL
  const token = searchParams.get('token');
  const productId = searchParams.get('productId');
  console.log('token', token, 'productId', productId);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AxiosClient.post('/reviews/submit-review', {
        token,
        rating,
        comment,
      });
      alert('Review submitted successfully!');
    } catch (error) {
      alert('Failed to submit review.');
    }
  };
  useEffect(() => {
    console.log('useEffect triggered. productId:', productId);

    // Validate token (optional)
    AxiosClient.post('/reviews/validate-token', { token })
      .then(() => setIsValid(true))
      .catch(() => setIsValid(false));

    // Fetch product details
    const fetchProduct = async () => {
      try {
        const response = await AxiosClient.get(`/products/${productId}`, {
          params: {
            submitReview: true,
          },
        });
        console.log('Fetched Product:', response.data);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    if (productId) fetchProduct();
  }, []);

  if (isValid === null) return <p>Loading...</p>; // Token validation loading state
  if (!isValid) return <p>Token expired or invalid</p>; // Token validation failure message

  if (!product) {
    return <p>Loading product details...</p>;
  }

  return (
    <form
      onSubmit={handleSubmit}
      className='max-w-lg mx-auto my-10 p-8 bg-white rounded-lg shadow-lg space-y-6'
    >
      <h2 className='text-3xl font-bold text-gray-800 text-center'>
        Review Our Product
      </h2>
      <p className='text-gray-500 text-center'>
        Share your thoughts about our product with other customers
      </p>

      <div className='flex items-center space-x-4'>
        <div className='w-20 h-20 bg-gray-200 rounded-lg flex items-center justify-center'>
          {product?.productImages?.length > 0 ? (
            <img
              src={product?.productImages?.[0]?.file?.url}
              alt={product?.name}
              className='w-full h-full object-cover rounded-lg'
            />
          ) : (
            <span className='text-gray-400'>No Image</span>
          )}
        </div>
        <div>
          <h3 className='text-xl font-bold text-gray-800'>{product?.name}</h3>
        </div>
      </div>

      {/* Star Rating Section */}
      <div className='space-y-2'>
        <label className='block text-gray-700 font-semibold'>
          Your Rating:
        </label>
        <div className='flex space-x-2'>
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type='button'
              onClick={() => setRating(star)}
              className={`text-3xl ${
                rating >= star ? 'text-yellow-400' : 'text-gray-300'
              }`}
            >
              ★
            </button>
          ))}
        </div>
      </div>

      {/* Review Comment Section */}
      <div className='space-y-2'>
        <label className='block text-gray-700 font-semibold'>
          Your Review:
        </label>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder='What did you like or dislike? What did you use this product for?'
          className='w-full p-4 border rounded-lg focus:outline-none focus:border-blue-500 transition-colors resize-none h-32'
        />
      </div>

      {/* Submit Button */}
      <button
        type='submit'
        className='w-full bg-blue-600 text-white font-semibold py-3 rounded-lg hover:bg-blue-700 transition-all duration-300'
      >
        Submit Review
      </button>
    </form>
  );
};

export default SubmitReview;
