import React, { useState, useCallback, useEffect } from "react";
import Aos from "aos";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ProductCard from "./ProductCard";
import AxiosClient from "utilities/axiosClient";
import { toast } from "react-toastify";
import './shop.css'

dayjs.extend(utc);
dayjs.extend(timezone);

const ViewProduct = () => {
  const [products, setProducts] = useState([]);

  const fetchProducts = useCallback(async () => {
    try {
      const userTimezone = dayjs.tz.guess();
      const response = await AxiosClient.get("/products"
      // , {
      //   params: { timezone: userTimezone },
      // }
      );

      if (response.data?.length > 0) {
        setProducts(response.data);
      } else {
        setProducts([]);
        toast.info("No products found!");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Failed to load products. Please try again.");
    }
  }, []);

  useEffect(() => {
    Aos.init({ duration: 600, delay: 200 });
    fetchProducts();
  }, [fetchProducts]);

  const handleProductDeleted = (deletedProductId) => {
    setProducts((prev) => prev.filter((product) => product.id !== deletedProductId));
  };

  return (
    <div className="shop-container">
      <h2 className="shop-title" data-aos="fade-up">
        Our Products
      </h2>
      {products.length > 0 ? (
        <div className="product-grid-shop">
          {products.map((product, index) => (
            <div
              key={product.id}
              data-aos="fade-up"
              data-aos-delay={`${index * 100}`}
            >
              <ProductCard
                id={product.id}
                imageUrl={product.productImages[0]?.file.url || ""}
                name={product.name}
                price={product.price}
                currency = {product.currency}
                onProductDeleted={handleProductDeleted}
              />
            </div>
          ))}
        </div>
      ) : (
        <p className="no-products">No products available</p>
      )}
    </div>
  );
};

export default ViewProduct;
