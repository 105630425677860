import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashCan,
  faXmark,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { faOpencart } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import './CartSlider.css';

const CartSlider = () => {
  const {
    cart,
    isCartOpen,
    toggleCart,
    updateOrCreateCart,
    currency,
    loadingItems,
  } = useCart();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isCartOpen) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Simulate loading time
    } else {
      setLoading(true);
    }
  }, [isCartOpen]);

  const handleCheckout = () => {
    navigate('/checkout-form', { state: { cartItems: cart } });
    toggleCart();
  };

  const handleQuantityChange = (productId, currentQuantity, delta) => {
    const newQuantity = currentQuantity + delta;
    updateOrCreateCart(productId, newQuantity <= 0 ? 0 : newQuantity);
  };

  const handleRemoveProduct = (productId) => {
    updateOrCreateCart(productId, 0);
  };

  return (
    <div className={`cart-slider ${isCartOpen ? 'open-up' : ''}`}>
      <div className='cart-slider-header'>
        <h2>Your Cart</h2>
        <button className='close-btn-CS' onClick={toggleCart}>
          <FontAwesomeIcon icon={faXmark} size='sm' />
        </button>
      </div>
      <div className='slider-underline'></div>
      <div className='cart-slider-content'>
        {loading ? (
          <ul className='cart-items-list'>
            {[...Array(3)].map((_, index) => (
              <li key={index} className='cart-item'>
                <div className='product-info'>
                  <div className='cart-skeleton-image'></div>
                  <div className='product-detail-CS'>
                    <div className='name-price'>
                      <div className='cart-skeleton-text cart-skeleton-name'></div>
                      <div className='cart-skeleton-text cart-skeleton-price'></div>
                    </div>
                    <div className='quantity-remove'>
                      <div className='cart-skeleton-quantity-controls'></div>
                      <div className='cart-skeleton-remove-btn'></div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : cart.length === 0 ? (
          <div className='empty-cart'>
            <FontAwesomeIcon icon={faOpencart} className='empty-cart-icon' />
            <p className='empty-cart-text'>Your cart is empty!</p>
          </div>
        ) : (
          <ul className='cart-items-list'>
            {cart.map((item) => {
              const isItemLoading = loadingItems.includes(item.productId);
              return (
                <li key={item.productId} className='cart-item'>
                  <div
                    className={`product-info ${
                      isItemLoading ? 'loading-state' : ''
                    }`}
                  >
                    {isItemLoading && (
                      <div className='loader-overlay'>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className='fa-spin'
                          size='2x'
                        />
                      </div>
                    )}
                    <img
                      src={item.imageUrl || '/placeholder.png'}
                      alt={item.name || 'Product Image'}
                      className='product-image-CS'
                    />
                    <div className='product-detail-CS'>
                      <div className='name-price'>
                        <p className='product-name'>{item.name}</p>
                        <div className='item-price'>
                          {item.currency}{' '}
                          {(item.price * item.quantity).toFixed(2)}
                        </div>
                      </div>
                      <div className='quantity-remove'>
                        <div className='quantity-controls-CS'>
                          <button
                            className='quantity-btn-CS'
                            onClick={() =>
                              handleQuantityChange(
                                item.productId,
                                item.quantity,
                                -1,
                              )
                            }
                            disabled={isItemLoading}
                          >
                            -
                          </button>
                          <span>{item.quantity}</span>
                          <button
                            className='quantity-btn-CS'
                            onClick={() =>
                              handleQuantityChange(
                                item.productId,
                                item.quantity,
                                1,
                              )
                            }
                            disabled={isItemLoading}
                          >
                            +
                          </button>
                        </div>
                        <button
                          className='remove-btn'
                          onClick={() => handleRemoveProduct(item.productId)}
                          disabled={isItemLoading}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {loading ? (
        <div className='cart-total-section'>
          <div className='cart-skeleton-text cart-skeleton-total'></div>
          <div className='cart-skeleton-button'></div>
        </div>
      ) : cart.length > 0 ? (
        <div className='cart-total-section'>
          <div className='total-price'>
            <p className='totaltext'>Subtotal:</p>
            <p className='totalnumber'>
              {currency}{' '}
              {cart
                .reduce((total, item) => total + item.price * item.quantity, 0)
                .toFixed(2)}
            </p>
          </div>
          <button className='checkout-btn-CS' onClick={handleCheckout}>
            Proceed to Checkout
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default CartSlider;
