import React, { useState } from 'react';
import './Section5.css';
import { FiSend } from 'react-icons/fi';
import AxiosClient from 'utilities/axiosClient';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle2 } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';

const Section5 = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      await AxiosClient.post('/products/subscribe', { email });
      setIsSubmitting(false);
      setIsModalOpen(true);
      setEmail('');
      toast.success(
        ' Thank you for subscribing! We will notify you when new products arrive.', null , 'bottom-left'
      );
    } catch (err) {
      setIsSubmitting(false);
      setError('An error occurred. Please try again.');
    }
  };
  
  return (
    <div  data-aos-once="true" data-aos='zoom-in' className='section5-container'>
      <div className='backdrop-blur-sm py-14'>
        <div className='space-y-6 max-w-xl mx-4'>
          <h1 data-aos-once="true" data-aos='fade-down'  className='section5-title'>
            Get Notified About New Products
          </h1>
          <form
            className='section5-input-container'
            data-aos='fade-up'
            data-aos-once="true"
            onSubmit={handleSubscribe}
          >
            <input
              type='email'
              placeholder='Enter your email'
              className='section5-input'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              className='section5-button'
              type='submit'
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className='section5-loader'></span>
              ) : (
                <FiSend className='section5-icon' />
              )}
            </button>
          </form>
          {error && <p className='section5-error-text'>{error}</p>}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Section5;
