import React, { useState } from 'react';
import './AffiliateProgram.css';
import { motion } from 'framer-motion';
import AxiosClient from 'utilities/axiosClient';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AffiliateProgram = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    topics: '',
    about: '',
    instagramLink: '',
    facebookLink: '',
    tiktokLink: '',
    youtubeLink: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AxiosClient.post('/affiliate/apply', formData);
      toast.success('Application submitted successfully!', {
        position: 'top-center',
        autoClose: 5000,
      });
      // Reset form
      setFormData({
        name: '',
        email: '',
        phone: '',
        topics: '',
        about: '',
        instagramLink: '',
        facebookLink: '',
        tiktokLink: '',
        youtubeLink: '',
      });
    } catch (error) {
      console.error('Error submitting application:', error);
      toast.error('Failed to submit application. Please try again.', {
        position: 'top-center',
        autoClose: 5000,
      });
    }
  };

  return (
    <div className='affiliate-container'>
      <div className='affiliate-content'>
        {/* Heading */}
        <motion.h2
          className='affiliate-heading'
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Do you want to be part of the nutrition revolution?
        </motion.h2>

        {/* Description */}
        <motion.p
          className='affiliate-text'
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Join our affiliate community to earn while you share the amazing
          benefits of our products. Sign up below and start your journey.
        </motion.p>

        {/* Form */}
        <form className='affiliate-form' onSubmit={handleSubmit}>
          {/* Row 1: Name and Email */}
          <div className='form-row'>
            <div className='form-group'>
              <motion.label
                htmlFor='name'
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
              >
                Name
              </motion.label>
              <motion.input
                type='text'
                id='name'
                className='affiliate-input'
                placeholder='Your Name'
                value={formData.name}
                onChange={handleChange}
                required
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
              />
            </div>
            <div className='form-group'>
              <motion.label
                htmlFor='email'
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                Email
              </motion.label>
              <motion.input
                type='email'
                id='email'
                className='affiliate-input'
                placeholder='Your Email'
                value={formData.email}
                onChange={handleChange}
                required
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              />
            </div>
          </div>

          {/* Row 2: Phone and Topics */}
          <div className='form-row'>
            <div className='form-group'>
              <motion.label
                htmlFor='phone'
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.3 }}
              >
                Phone
              </motion.label>
              <motion.input
                type='tel'
                id='phone'
                className='affiliate-input'
                placeholder='Your Phone Number'
                value={formData.phone}
                onChange={handleChange}
                required
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.3 }}
              />
            </div>
            <div className='form-group'>
              <motion.label
                htmlFor='topics'
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                Topics of Focus
              </motion.label>
              <motion.input
                type='text'
                id='topics'
                className='affiliate-input'
                placeholder='What topics do you focus on?'
                value={formData.topics}
                onChange={handleChange}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              />
            </div>
          </div>

          {/* Row 3: About */}
          <div className='form-row'>
            <div className='form-group'>
              <motion.label
                htmlFor='about'
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.5 }}
              >
                Tell Us About Yourself
              </motion.label>
              <motion.textarea
                id='about'
                className='affiliate-textarea'
                placeholder='Your Story'
                value={formData.about}
                onChange={handleChange}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.5 }}
              ></motion.textarea>
            </div>
          </div>

          {/* Promotion Heading */}
          <motion.h3
            className='affiliate-subheading'
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            Where will you be promoting us?
          </motion.h3>

          {/* Social Media Links */}
          <div className='form-row'>
            <div className='form-group'>
              <motion.label
                htmlFor='instagramLink'
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.7 }}
              >
                Instagram Link
              </motion.label>
              <motion.input
                type='text'
                id='instagramLink'
                className='affiliate-input'
                placeholder='Your Instagram Link'
                value={formData.instagramLink}
                onChange={handleChange}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.7 }}
              />
            </div>
            <div className='form-group'>
              <motion.label
                htmlFor='facebookLink'
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.8 }}
              >
                Facebook Link
              </motion.label>
              <motion.input
                type='text'
                id='facebookLink'
                className='affiliate-input'
                placeholder='Your Facebook Link'
                value={formData.facebookLink}
                onChange={handleChange}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.8 }}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group'>
              <motion.label
                htmlFor='tiktokLink'
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.7 }}
              >
                TikTok Link
              </motion.label>
              <motion.input
                type='text'
                id='tiktokLink'
                className='affiliate-input'
                placeholder='Your TikTok Link'
                value={formData.tiktokLink}
                onChange={handleChange}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.7 }}
              />
            </div>
            <div className='form-group'>
              <motion.label
                htmlFor='youtubeLink'
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.8 }}
              >
                YouTube Link
              </motion.label>
              <motion.input
                type='text'
                id='youtubeLink'
                className='affiliate-input'
                placeholder='Your YouTube Link'
                value={formData.youtubeLink}
                onChange={handleChange}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.8 }}
              />
            </div>
          </div>

          {/* Submit Button */}
          <motion.button
            className='buttonaffiliate'
            type='submit'
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.95 }}
          >
            Submit
          </motion.button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AffiliateProgram;
