import React, { useState } from 'react';
import AxiosClient from 'utilities/axiosClient';

const Popup = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');

    try {
      const response = await AxiosClient.post('/coupons/generate-coupon', {
        email,
      });

      if (response.status === 200) {
        // Assuming the response contains useful data
        console.log('Coupon generated successfully:', response.data);
        setStatus('success');
      } else {
        throw new Error(response.data.error || 'Failed to generate coupon');
      }
    } catch (err) {
      console.error('Error:', err);
      setStatus('error');
    }
  };

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50'>
      <div className='bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center relative'>
        <button
          onClick={onClose}
          className='absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none'
        >
          X
        </button>

        {status === 'success' ? (
          <>
            <h2 className='text-xl font-bold mb-4'>Coupon Generated!</h2>
            <p className='mb-4'>
              Check your email for the 20% off coupon code.
            </p>
            <button onClick={onClose} className='text-blue-600 underline mt-4'>
              Continue Shopping
            </button>
          </>
        ) : (
          <>
            <h2 className='text-xl font-bold mb-4'>
              Get 20% Off Your First Order
            </h2>
            <p className='mb-4'>
              Enter your email to receive your 20% off code.
            </p>
            <form onSubmit={handleSubmit}>
              <input
                type='email'
                placeholder='Email address'
                className='border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type='submit'
                disabled={status === 'loading'}
                className='bg-[#0b1b32] text-white px-4 py-2 rounded-md hover:bg-opacity-90 w-full'
              >
                {status === 'loading' ? 'Generating...' : 'Get My 20% Off'}
              </button>
            </form>
            <button onClick={onClose} className='text-blue-600 underline mt-4'>
              Continue Shopping
            </button>
            {status === 'error' && (
              <p className='text-red-600 mt-2'>
                Error generating coupon. Please try again.
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Popup;
