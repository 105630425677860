// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import './Products.css';

// const Products = () => {
//   const [products, setProducts] = useState([]);

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         const response = await axios.get('http://localhost:5000/api/products');
//         setProducts(response.data);
//       } catch (error) {
//         console.error('Error fetching products:', error);
//       }
//     };
//     fetchProducts();
//   }, []);

//   return (
//     <div className='products'>
//       <h2>All Products</h2>
//       <div className='product-list'>
//         {products.map((product) => (
//           <Link
//             to={`/admin/secret-dashboard/products/${product.id}`}
//             key={product.id}
//             className='product-item'
//           >
//             <h3>{product.name}</h3>
//             <p>${product.price}</p>
//           </Link>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Products;
import React from "react";
import Shop from "../../Shop/Shop"; // Import the shared Shop component
import ViewProduct from "./ViewProduct/ViewProduct";

const Products = () => {
  return (
    <div className="products">
      <ViewProduct />
    </div>
  );
};

export default Products;
