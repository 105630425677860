// ChatBox.jsx
import React, { useState, useRef, useEffect } from 'react';
import { FaCommentDots, FaTimes, FaPaperPlane, FaBox } from 'react-icons/fa';
import { RiAttachment2 } from 'react-icons/ri';
import { useChat } from '../../contexts/ChatContext';
import './ChatBox.css';
import AxiosClient from 'utilities/axiosClient';
import { v4 as uuidv4 } from 'uuid';

const now = new Date();

const ChatBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [uploadingMessage, setUploadingMessage] = useState(null);
  const [isTrackingOrder, setIsTrackingOrder] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [email, setEmail] = useState('');
  const chatBodyRef = useRef(null);
  const { sendMessage, getChat, socket, trackOrder } = useChat();
  const [typing, setTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [loading, setLoading] = useState(false);

  // Auto scroll to the latest message
  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0]; // Only one file allowed
    if (!file) return;

    const uniqueId = uuidv4(); // Generate a unique ID for the temporary message

    const formData = new FormData();
    formData.append('file', file); // Add the selected file to the form
    formData.append('sender', 'USER');
    formData.append('chatRoomId', messages[0]?.chatRoomId || null);
    formData.append('tempId', uniqueId); // Include the tempId in the form data

    // Create a temporary message for the uploading file
    const tempMessage = {
      id: uniqueId, // Assign the unique ID
      chatRoomId: messages[0]?.chatRoomId || null,
      text: null,
      senderType: 'USER',
      chatFiles: [
        {
          id: uuidv4(), // Unique ID for the file
          file: {
            url: URL.createObjectURL(file),
            name: file.name,
            type: file.type,
            isUploading: true, // Mark as uploading
          },
        },
      ],
      createdAt: new Date().toISOString(),
    };

    // Set the uploadingMessage state
    setUploadingMessage(tempMessage);

    try {
      const response = await AxiosClient.post('/chat/messages', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Replace the temporary message with the actual one
      setMessages((prev) =>
        prev.map((message) =>
          message.id === response.data[0].tempId // Match based on tempId
            ? {
                ...response.data[0],
                id: response.data[0].tempId, // Use tempId as the message id
                senderType: response.data[0].senderType,
                chatFiles: response.data[0].chatFiles.map((file) => ({
                  ...file,
                  isTemporary: false,
                  isUploading: false,
                })),
              }
            : message,
        ),
      );
      setUploadingMessage(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      // Optionally, handle the error
      setUploadingMessage(null);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const fetchChat = async () => {
        setLoading(true);
        const chatData = await getChat();
        setMessages(chatData?.messages || []);
        socket.emit('joinRoom', { chatRoomId: chatData?.chatRoomId });
        setLoading(false);
      };
      fetchChat();
      socket.on('newMessage', (message) => {
        setMessages((prev) => [...prev, message]);
      });

      socket.on('typing', (data) => {
        setTyping(data.isTyping);
      });

      return () => {
        socket.off('newMessage');
        socket.off('typing');
      };
    }
  }, [isOpen, getChat, socket]);

  useEffect(() => {
    if (isOpen) {
      scrollToBottom();
    }
  }, [isOpen, socket]);

  useEffect(() => {
    if (messages.length > 0 || uploadingMessage) {
      scrollToBottom();
    }
  }, [messages, uploadingMessage]);

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) {
      return; // Don't send an empty message
    }

    sendMessage(inputMessage, 'USER', null); // Include file IDs in message
    const messageData = {
      text: inputMessage,
      senderType: 'USER',
      createdAt: new Date(),
    };
    // setMessages((prev) => [...prev, messageData]);
    setInputMessage('');
  };

  const handleTrackOrder = () => {
    setIsTrackingOrder(true);
    sendMessage('I would like to track my order.', 'USER');
    sendMessage(
      'Please provide your Order ID and email address to proceed.',
      'ADMIN',
    );
    // setMessages((prev) => [
    //   ...prev,
    //   {
    //     senderType: 'USER',
    //     text: 'I would like to track my order.',
    //     createdAt: now,
    //   },
    //   {
    //     senderType: 'ADMIN',
    //     text: 'Please provide your Order ID and email address to proceed.',
    //     createdAt: now,
    //   },
    // ]);
  };

  const handleSubmitTracking = async (e) => {
    e.preventDefault();
    if (orderId && email) {
      setMessages((prev) => [
        ...prev,
        {
          senderType: 'USER',
          text: `Order ID: ${orderId}, Email: ${email}`,
          createdAt: now,
        },
      ]);
      sendMessage(`Order ID: ${orderId}, Email: ${email}`, 'USER');
      const trackingResponse = await trackOrder(
        orderId,
        email,
        messages?.[0]?.chatRoomId,
      );

      if (trackingResponse) {
        // Display the tracking message from the response
        sendMessage(trackingResponse.trackingMessage, 'ADMIN');

        // Optionally, if tracking history is available, display it
        if (
          trackingResponse?.trackingHistory &&
          trackingResponse.trackingHistory.length > 0
        ) {
          trackingResponse.trackingHistory.forEach((trackInfo) => {
            const {
              trackingNumber,
              status,
              location,
              estimatedDeliveryFrom,
              estimatedDeliveryTo,
            } = trackInfo;

            const detailedMessage = `Tracking Number: ${trackingNumber}, Status: ${status}, Location: ${
              location || 'N/A'
            }, Estimated Delivery: ${estimatedDeliveryFrom || 'N/A'} to ${
              estimatedDeliveryTo || 'N/A'
            }.`;

            sendMessage(detailedMessage, 'ADMIN');
            setMessages((prev) => [
              ...prev,
              {
                senderType: 'ADMIN',
                text: detailedMessage,
                createdAt: now,
              },
            ]);
          });
        }
      } else {
        sendMessage('Order not found with the provided information.', 'ADMIN');
        setMessages((prev) => [
          ...prev,
          {
            senderType: 'ADMIN',
            text: 'Order not found with the provided information.',
            createdAt: now,
          },
        ]);
      }

      setIsTrackingOrder(false);
      setOrderId('');
      setEmail('');
    }
  };

  const handleCancelTracking = () => {
    setIsTrackingOrder(false);
    setOrderId('');
    setEmail('');
    sendMessage('Order tracking request canceled.', 'USER');
    setMessages((prev) => [
      ...prev,
      {
        senderType: 'ADMIN',
        text: 'Order tracking canceled. How else can I assist you?',
        createdAt: now,
      },
    ]);
  };
  const handleKeyDown = () => {
    if (!typing) {
      socket.emit('typing', { chatRoomId, isTyping: true });
    }
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
  };

  const handleKeyUp = () => {
    const timeout = setTimeout(() => {
      socket.emit('typing', { chatRoomId, isTyping: false });
    }, 500);
    setTypingTimeout(timeout);
  };

  const chatRoomId = messages[0]?.chatRoomId;

  return (
    <div className='chat-box'>
      <button className='chat-toggle' onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <FaTimes /> : <FaCommentDots />}
      </button>

      {isOpen && (
        <div className='chat-window'>
          <div className='chat-header'>Chat with us</div>
          <div className='chat-body' ref={chatBodyRef}>
            {loading ? (
              <div className='chat-skeleton-messages'>
                {[...Array(4)].map((_, index) => {
                  const isUser = index % 2 === 0; // Alternate between user and admin
                  return (
                    <div
                      key={index}
                      className={`chat-skeleton-message ${
                        isUser ? 'chat-skeleton-user' : 'chat-skeleton-admin'
                      }`}
                    >
                      <div
                        className={`chat-skeleton-bubble ${
                          isUser ? 'user-bubble' : 'admin-bubble'
                        }`}
                      >
                        <div className='chat-skeleton-text'></div>
                        <div className='chat-skeleton-text short'></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                {/* Render existing messages */}
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={`message-bubble-chatbox ${message?.senderType?.toLowerCase()}-bubble`}
                  >
                    {message?.text && <p>{message.text}</p>}

                    {/* Render files */}
                    {message?.chatFiles?.map((file) => (
                      <div key={file.id} className='file-message'>
                        {file.isUploading ? (
                          // Skeleton for file being uploaded
                          <div className='file-skeleton'>
                            <div className='file-skeleton-thumbnail'></div>
                            <div className='file-skeleton-name'></div>
                          </div>
                        ) : file.file.type.startsWith('image/') ? (
                          <div className='image-upload-container'>
                            <img
                              src={file.file.url}
                              alt={file.file.name}
                              className='message-image'
                              loading='lazy'
                            />
                          </div>
                        ) : (
                          <div className='file-upload-container'>
                            <span>{file.file.name}</span>
                          </div>
                        )}
                      </div>
                    ))}

                    <span className='message-timestamp'>
                      {new Date(message.createdAt).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </span>
                  </div>
                ))}

                {/* Render the uploadingMessage if it exists */}
                {uploadingMessage && (
                  <div
                    key={uploadingMessage.id}
                    className={`message-bubble-chatbox ${uploadingMessage?.senderType?.toLowerCase()}-bubble`}
                  >
                    {/* Render text if available */}
                    {uploadingMessage?.text && <p>{uploadingMessage?.text}</p>}

                    {/* Render files (image or links) */}
                    {uploadingMessage?.chatFiles?.map((file) => (
                      <div key={file?.id} className='file-message'>
                        {file?.file?.type?.startsWith('image/') ? (
                          <div className='image-upload-container'>
                            <img
                              src={file?.file?.url}
                              alt={file?.file?.name}
                              className='message-image'
                              loading='lazy'
                              style={{
                                opacity: file?.file?.isUploading ? 0.5 : 1,
                              }}
                            />
                            {file?.file?.isUploading && (
                              <div className='image-upload-overlay'>
                                <div className='loader'></div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className='file-upload-container'>
                            <span>{file?.file?.name}</span>
                            {file?.file?.isUploading && (
                              <div className='file-upload-loader'>
                                <div className='loader'></div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}

                    {/* Timestamp */}
                    <span className='message-timestamp'>
                      {new Date(uploadingMessage?.createdAt).toLocaleTimeString(
                        [],
                        {
                          hour: '2-digit',
                          minute: '2-digit',
                        },
                      )}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>

          <div className='chat-input-area'>
            {isTrackingOrder ? (
              <form onSubmit={handleSubmitTracking} className='tracking-form'>
                <input
                  type='text'
                  placeholder='Enter Order ID'
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                  required
                />
                <input
                  type='email'
                  placeholder='Enter Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className='button-group'>
                  <button type='submit' className='track-order-btn'>
                    <FaBox /> Track Order
                  </button>
                  <button
                    type='button'
                    className='cancel-btn'
                    onClick={handleCancelTracking}
                  >
                    <FaTimes /> Cancel
                  </button>
                </div>
              </form>
            ) : (
              <>
                {typing && (
                  <p className='typing-indicator'>Admin is typing...</p>
                )}
                <button className='track-order-btn' onClick={handleTrackOrder}>
                  <FaBox /> Track Order
                </button>

                <div className='message-input-container'>
                  <textarea
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder='Type your message...'
                    rows={1}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        // Check for Enter key without Shift
                        e.preventDefault(); // Prevent newline
                        handleSendMessage(); // Send the message
                      }
                      handleKeyDown(); // Trigger typing indicator
                    }}
                    onKeyUp={handleKeyUp}
                    className='message-input'
                  />

                  <label className='file-upload-label'>
                    <RiAttachment2 />
                    <input
                      type='file'
                      accept='image/*'
                      onChange={handleFileChange}
                      hidden
                    />
                  </label>

                  <button
                    className='send-btn'
                    onClick={handleSendMessage}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                  >
                    <FaPaperPlane />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
