import React from "react";
import "./Section16.css";

import apple from "../../../assets/6LYETS_RENDER_APPLE.png";
import lime from "../../../assets/6LYETS_RENDER_LIME.png";
import orange from "../../../assets/6LYETS_RENDER_ORANGE.png";
import watermelon from "../../../assets/6LYETS_RENDER_WATERMELON.png";

const Section16 = () => {
  const categories = [
    {
      title: "APPLE FLAVOUR",
      description: "",
      image: apple,
      link: "/collections/products/1",
    },
    {
      title: "LIME FLAVOUR",
      description: "",
      image: lime,
      link: "/collections/products/2",
    },

    {
      title: "WATERMELON FLAVOUR",
      description: "",
      image: watermelon,
      link: "/collections/products/3",
    },
    {
      title: "ORANGE FLAVOUR",
      description: "",
      image: orange,
      link: "/collections/products/4",
    },
  ];

  return (
    <div className="section16-container">
      <div className="section16-text">
        <h2 className="section16-heading">Check out our latest Flavour</h2>
        <p className="section16-p">
          Indulge in the Taste of Our Newest Flavor Sensation!
        </p>
      </div>
      <div className="categories">
        {categories.map((category, index) => (
          <div className="category-card" key={index}>
            <img
              src={category.image}
              alt={category.title}
              className="category-image"
            />
            <div className="category-content">
              <h3>{category.title}</h3>
              <p>{category.description}</p>
              <a href={category.link} className="shop-now-btn">
                SHOP NOW
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section16;
