// ChatRoom.jsx
import React, { useState, useEffect, useRef } from 'react';
import { FaUserCircle, FaShieldAlt, FaPaperPlane } from 'react-icons/fa';
import { RiAttachment2 } from 'react-icons/ri';
import { useChat } from '../../../../contexts/ChatContext';
import AxiosClient from 'utilities/axiosClient';
import { v4 as uuidv4 } from 'uuid';
import './ChatRoom.css';

const ChatRoom = ({ chatRoomId }) => {
  const [inputMessage, setInputMessage] = useState('');
  const { sendMessage, getChat, socket } = useChat();
  const [allMessages, setAllMessages] = useState([]);
  const [uploadingMessage, setUploadingMessage] = useState(null); // New state variable
  const [typing, setTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const chatBodyRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchChat = async () => {
      setLoading(true); // Start loading
      const response = await getChat(chatRoomId);
      const { messages } = response;
      setAllMessages(messages);
      socket.emit('joinRoom', { chatRoomId });
      setLoading(false); // End loading
    };
    fetchChat();

    // Listen for new messages and typing events
    socket.on('newMessage', (message) => {
      setAllMessages((prevMessages) => [...prevMessages, message]);
    });

    socket.on('typing', (data) => {
      setTyping(data.isTyping);
    });

    return () => {
      socket.off('newMessage');
      socket.off('typing');
    };
  }, [chatRoomId, getChat, socket]);

  useEffect(() => {
    scrollToBottom(); // Scroll to the bottom whenever new messages are added
  }, [allMessages, uploadingMessage]);

  // Auto scroll to the latest message
  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0]; // Only one file allowed
    if (!file) return;

    const uniqueId = uuidv4(); // Generate a unique ID for the temporary message

    const formData = new FormData();
    formData.append('file', file); // Add the selected file to the form
    formData.append('sender', 'ADMIN');
    formData.append('chatRoomId', parseInt(chatRoomId));
    formData.append('tempId', uniqueId); // Include the tempId in the form data

    // Create a temporary message for the uploading file
    const tempMessage = {
      id: uniqueId, // Assign the unique ID
      chatRoomId,
      text: null,
      senderType: 'ADMIN',
      chatFiles: [
        {
          id: uuidv4(), // Unique ID for the file
          url: URL.createObjectURL(file),
          name: file.name,
          type: file.type,
          isUploading: true, // Mark as uploading
        },
      ],
      createdAt: new Date().toISOString(),
    };

    // Set the uploadingMessage state
    setUploadingMessage(tempMessage);

    try {
      const response = await AxiosClient.post('/chat/messages', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Reset uploadingMessage
      setUploadingMessage(null);

      // The new message will come through the socket and be added to allMessages
    } catch (error) {
      console.error('Error uploading file:', error);
      // Optionally, handle the error
      setUploadingMessage(null);
    }
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) {
      return; // Don't send an empty message
    }

    setInputMessage(''); // Clear input

    try {
      await sendMessage(inputMessage, 'ADMIN', chatRoomId);

      // The message will be received via the 'newMessage' event from the server
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyDown = () => {
    if (!typing) {
      socket.emit('typing', { chatRoomId, isTyping: true });
    }
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
  };

  const handleKeyUp = () => {
    const timeout = setTimeout(() => {
      socket.emit('typing', { chatRoomId, isTyping: false });
    }, 500);
    setTypingTimeout(timeout);
  };

  return (
    <div className='chat-room'>
      <div className='messages' ref={chatBodyRef}>
        {loading ? (
          <div className='chat-loader'>
            <div className='loader'></div>
          </div>
        ) : (
          <>
            {/* Render existing messages */}
            {allMessages?.map((msg) => (
              <div
                key={msg.id}
                className={`message-bubble ${
                  msg.senderType === 'ADMIN' ? 'admin' : 'user'
                }`}
              >
                <div className='message-content'>
                  {msg.senderType === 'ADMIN' ? (
                    <FaShieldAlt className='message-icon admin-icon' />
                  ) : (
                    <FaUserCircle className='message-icon user-icon' />
                  )}
                  {/* Render text if available */}
                  {msg?.text && <p className='message-text'>{msg.text}</p>}

                  {/* Render files (images or other files) */}
                  {msg?.chatFiles?.map((file) => (
                    <div key={file?.id} className='file-message'>
                      {file?.file?.type?.startsWith('image/') ? (
                        <div className='image-upload-container'>
                          <img
                            src={file?.file?.url}
                            alt={file?.file?.name}
                            className='message-image'
                            loading='lazy'
                            style={{
                              opacity: file?.file?.isUploading ? 0.5 : 1,
                            }}
                          />
                          {file?.file?.isUploading && (
                            <div className='image-upload-overlay'>
                              <div className='loader'></div>
                            </div>
                          )}
                        </div>
                      ) : (
                        // Handle non-image files
                        <div className='file-upload-container'>
                          <span>{file?.file?.name}</span>
                          {file?.file?.isUploading && (
                            <div className='file-upload-loader'>
                              <div className='loader'></div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {/* Timestamp */}
                <span className='message-timestamp'>
                  {new Date(msg?.createdAt).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </span>
              </div>
            ))}

            {/* Render the uploadingMessage if it exists */}
            {uploadingMessage && (
              <div
                key={uploadingMessage.id}
                className={`message-bubble ${
                  uploadingMessage.senderType === 'ADMIN' ? 'admin' : 'user'
                }`}
              >
                <div className='message-content'>
                  {uploadingMessage.senderType === 'ADMIN' ? (
                    <FaShieldAlt className='message-icon admin-icon' />
                  ) : (
                    <FaUserCircle className='message-icon user-icon' />
                  )}
                  {/* Render text if available */}
                  {uploadingMessage?.text && (
                    <p className='message-text'>{uploadingMessage.text}</p>
                  )}

                  {/* Render files (images or other files) */}
                  {uploadingMessage?.chatFiles?.map((file) => (
                    <div key={file?.id} className='file-message'>
                      {file?.type?.startsWith('image/') ? (
                        <div className='image-upload-container'>
                          <img
                            src={file?.url}
                            alt={file?.name}
                            className='message-image'
                            loading='lazy'
                            style={{
                              opacity: file?.isUploading ? 0.5 : 1,
                            }}
                          />
                          {file?.isUploading && (
                            <div className='image-upload-overlay'>
                              <div className='loader'></div>
                            </div>
                          )}
                        </div>
                      ) : (
                        // Handle non-image files
                        <div className='file-upload-container'>
                          <span>{file?.name}</span>
                          {file?.isUploading && (
                            <div className='file-upload-loader'>
                              <div className='loader'></div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {/* Timestamp */}
                <span className='message-timestamp'>
                  {new Date(uploadingMessage?.createdAt).toLocaleTimeString(
                    [],
                    {
                      hour: '2-digit',
                      minute: '2-digit',
                    },
                  )}
                </span>
              </div>
            )}
          </>
        )}
      </div>
      {typing && <p className='typing-indicator'>User is typing...</p>}
      <div className='input-area'>
        <div className='input-actions'>
          <textarea
            placeholder='Type a message'
            className='chatRoom-textarea'
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
          />
          <label className='file-upload-label'>
            <RiAttachment2 />
            <input
              type='file'
              accept='image/*' // Only accept images
              onChange={handleFileChange}
              hidden
            />
          </label>
          <button onClick={handleSendMessage} className='chatRoom-button'>
            <FaPaperPlane />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatRoom;
